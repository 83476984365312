import React, { useEffect, useState } from "react";
import { Routes, Route, useLocation, useNavigate} from "react-router-dom";
import Navbar from "./navbar";
import { auth } from "../utils/firebaseConfig";

// Pages
import Login from "../pages/login";
import Dashboard from "../pages/dashboard";
import Insights from "../pages/insights";
import Attendance from "../pages/attendance";
import Forms from "../pages/forms";
import Teachers from "../pages/teachers";
import Import from "../pages/import";
import Account from "../pages/account";

function AppContent() {
  const [user, setUser] = useState(null);
  const [loading, setLoading] = useState(true);
  const location = useLocation();
  const navigate = useNavigate();

  useEffect(() => {
    const unsubscribe = auth.onAuthStateChanged((user) => {
      setUser(user);
      setLoading(false);
    });

    return () => unsubscribe();
  }, []);

  const handleLogout = async () => {
    try {
      await auth.signOut();
      setUser(null);
      navigate("/login");
    } catch (error) {
      console.error("Error signing out:", error);
    }
  };

  if (loading) {
    return <div>Loading...</div>;
  }

  if (!user) {
    return <Login />;
  }

  return (
    <>
    {location.pathname !== "/login" && (
    <div className="md:flex min-h-screen bg-cefblue">
      {location.pathname !== "/login" && (
        <Navbar onLogout={handleLogout} />
      )}

      <div className="md:flex-1 h-screen overflow-y-auto mx-6">
        <Routes>
          {/* Main Pages*/}
          <Route path="/dashboard" element={<Dashboard />} />
          <Route path="/insights" element={<Insights />} />
          <Route path="/forms" element={<Forms />} />
          <Route path="/teachers" element={<Teachers/>} />
          <Route path="/import" element={<Import/>} />
          <Route path="/account" element={<Account/>} />
          
          {/* Sub Pages*/}
          <Route path="/attendance/:id" element={<Attendance />} />
        </Routes>
      </div>
    </div>
    )}
  </>
);
}

export default AppContent;