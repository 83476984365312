import React, { useEffect, useState } from "react";
import { BarChart, Bar, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer } from "recharts";
import { getEventByCounty, getAttendanceByCounty } from '../components/services';

const GoodnewsChart = (prop) => {
  const {county} = prop
  const [chartData, setChartData] = useState([]);
  const [kids, setKids] = useState();
  const [camps, setCamps] = useState();

  useEffect(() => {
    const getClubCounts = async () => {

      const campsData = await getEventByCounty('goodnewsdaycamp', county);
      const attendanceData= await getAttendanceByCounty('attendance', county);
       
      // Filter out records where camp is true
      const filteredAttendanceData = attendanceData.filter((attendance) => attendance.camp === true)

      // Calculate attendance counts by month
      const attendanceCounts = [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0];
      for (const attendance of filteredAttendanceData) {
        const attendanceDate = new Date(attendance.date);
        const month = attendanceDate.getMonth();
        attendanceCounts[month]++;
      }  
    
      const campCounts = [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0];
      for (const camp of campsData) {
        const campDate = new Date(camp.date);
        const month = campDate.getMonth();
        campCounts[month]++;
      }
        
      // Combine attendance counts and total month counts
      const chartData = attendanceCounts.map((count, index) => ({
        name: new Date(2023, index).toLocaleString("default", { month: "short" }),
        Kids: count,
        Camps: campCounts[index]
      }));
    
      // Calculate total attendance counts and total camp counts
      const totalAttendance = attendanceCounts.reduce((sum, count) => sum + count, 0);
      const totalCamps = campCounts.reduce((sum, count) => sum + count, 0);
      setKids(totalAttendance);
      setCamps(totalCamps);

      // Filter chartData to include only data for -1 June to August +1mps
      const filteredChartData = chartData.slice(4, 9);

      setChartData(filteredChartData);
    };

    getClubCounts();
  }, [county]);

  return (
     <div className="relative bg-cefblue2 shadow-lg z-30 p-1 rounded-lg">
      <h1 className='text-ceftext text-center py-6 lg:text-3xl lg:font-bold sm:py-2 sm:text-lg'> Camps </h1>
      <div className="bg-cefblue2 rounded">
        <ResponsiveContainer width="100%" height={400}>
          <BarChart data={chartData}>
            <CartesianGrid strokeDasharray="3 3" />
            <XAxis dataKey="name" />
            <YAxis />
            <Tooltip />
            <Legend />
            <Bar dataKey="Kids" fill="#18e200" />
            <Bar dataKey="Camps" fill="#0018e2" />
            <Legend
              payload={[
                { value: "Kids", type: "rect", color: "#18e200" },
                { value: "Camps", type: "rect", color: "#0018e2" },
              ]}
            />
            <text x="95%" y="10%" textAnchor="end" fontSize="20" fill="#999999">
              GoodNews Camps
            </text>
            <text x="95%" y="15%" textAnchor="end" fontSize="20" fill="#999999">
              Kids: {kids}
            </text>
            <text x="95%" y="20%" textAnchor="end" fontSize="20" fill="#999999">
              Camp: {camps}
            </text>
          </BarChart>
        </ResponsiveContainer>
      </div>
    </div>
  );
}

export default GoodnewsChart;
