import React from "react";
import { useContext, useState } from 'react';
import { Icon } from '@iconify/react';
import { UserDataContext } from "./UserDataContext";

/**
 * Displays the navigation bar with links and user information.
 *
 * @param {Function} onLogout - Callback function for user logout.
 * @returns {JSX.Element} - React component displaying the navigation bar.
 */
const Navbar = ({ onLogout }) => {
    const userData  = useContext(UserDataContext);

    let Links =[
        {name:"Calender",link:"/calender"},
        {name:"Forms",link:"/forms"},
        {name:"Reports",link:"/reports"},
        {name:"Teachers",link:"/teachers"},
        {name:"Insights",link:"/insights"},
        {name:"Donations",link:"/donations"},
        {name:"Resources",link:"/resources"},
        {name:"Account",link:"/account"},
        {name:"Import",link:"/import"},
        {
            name: "Logout",
            onClick: () => {
                onLogout();
            },
        },
      ]; let [open,setOpen]=useState(false);

    if (userData === null) {
        return null; // or you can return a loading indicator or an empty div
    }

    return (
        <>
        <div className="flex flex-col shadow-lv bg-cefblue2 items-center text-ceftext border-gray-700">
            {/* Logo */}
            <a className="hidden md:flex items-center justify-center w-full mt-3" href="/dashboard">
                <img
                    className="object-center md:h-8 lg:h-12 w-auto"
                    src="./images/logo.png"
                    alt="CEF" />
            </a>
            {/* User Info */}
            <div className="hidden lg:flex items-center w-full px-3 py-3 rounded font-semibold">
                <span className="ml-2 text-sm font-semibold"></span>
                Hi, {userData.firstName}
            </div>
            {/* Navigation Links */}
            <div className="hidden md:flex w-full border-t border-gray-700">
                <div className="flex flex-col items-center w-full mt-3">
                    <a className="flex items-center w-full py-3 px-3 mt-2 rounded hover:bg-cefblue hover:text-white" href="/dashboard">
                        <Icon icon="material-symbols:team-dashboard" />
                        <span className="hidden lg:flex ml-2 text-sm font-semibold">Dasboard</span>
                    </a>
                    <a className="flex items-center w-full py-3 px-3 mt-2 rounded hover:bg-cefblue hover:text-white" href="/events">
                        <Icon icon="uis:calender" />
                        <span className="hidden lg:flex ml-2 text-sm font-semibold">Calender</span>
                    </a>
                    <a className="flex items-center w-full py-3 px-3 mt-2 rounded hover:bg-cefblue hover:text-white" href="/forms">
                        <Icon icon="fluent:form-new-28-filled" />
                        <span className="hidden lg:flex ml-2 text-sm font-semibold">Forms</span>
                    </a>
                    <a className="flex items-center w-full py-3 px-3 mt-2 rounded hover:bg-cefblue hover:text-white" href="/reports">
                        <Icon icon="icon-park-solid:table-report" />
                        <span className="hidden lg:flex ml-2 text-sm font-semibold">Reports</span>
                    </a>
                    <a className="flex items-center w-full py-3 px-3 mt-2 rounded hover:bg-cefblue hover:text-white" href="/teachers">
                        <Icon icon="material-symbols:person" />
                        <span className="hidden lg:flex ml-2 text-sm font-semibold">Teachers</span>
                    </a>
                    { (userData?.role !== 1 || userData?.role === 5) && (
                    <a className="flex items-center w-full py-3 px-3 mt-2 rounded hover:bg-cefblue hover:text-white" href="/insights">
                        <Icon icon="material-symbols:analytics" />
                        <span className="hidden lg:flex ml-2 text-sm font-semibold">Insights</span>
                    </a>
                    )}

                    { (userData?.role === 1 || userData?.role === 5) && (
                        <a className="flex items-center w-full py-3 px-3 mt-2 rounded hover:bg-cefblue hover:text-white" href="/hq">
                            <Icon icon="clarity:building-solid" />
                            <span className="hidden lg:flex ml-2 text-sm font-semibold">HQ</span>
                        </a>
                    )}
                    <a className="flex items-center w-full py-3 px-3 mt-2 rounded hover:bg-cefblue hover:text-white" href="/donations">
                        <Icon icon="mdi:donation" />
                        <span className="hidden lg:flex ml-2 text-sm font-semibold">Donations</span>
                    </a>
                    <a className="flex items-center w-full py-3 px-3 mt-2 rounded hover:bg-cefblue hover:text-white" href="/resources">
                        <Icon icon="material-symbols:folder-copy" />
                        <span className="hidden lg:flex ml-2 text-sm font-semibold">Resources</span>
                    </a>
                    <a className="flex items-center w-full py-3 px-3 mt-2 rounded hover:bg-cefblue hover:text-white" href="/import">
                        <Icon icon="clarity:import-solid" />
                        <span className="hidden lg:flex ml-2 text-sm font-semibold">Import</span>
                    </a>
                </div>
            </div>
            {/* Account and Logout and */}
            <div className="hidden md:flex flex-col items-center w-full mt-auto">
                <a className="relative flex items-center w-full py-4 px-3 rounded hover:bg-cefblue hover:text-white" href="/account">
                    <Icon icon="ic:baseline-account-circle" />
                    <span className="hidden lg:flex ml-2 text-sm font-semibold">Account</span>
                </a>
                <button
                    onClick={(event) => {
                        event.preventDefault();
                        onLogout();
                    }}
                    className="relative flex items-center w-full py-4 px-3 mb-1 rounded hover:bg-cefblue hover:text-white ml-2 text-sm font-semibold">
                    <Icon icon="clarity:logout-solid" />
                    <span className ="hidden lg:flex ml-2 text-sm font-semibold">Logout</span>
                </button>
            </div> 
        </div>
        <div className='shadow-md w-full fixed top-0 left-0 z-40'>
            <div className="md:hidden bg-cefblue flex justify-between items-center w-full py-3 px-3 rounded hover:text-white">
                <div>
                    <a href="/dashboard">
                        <img
                            className="object-center h-8 w-auto"
                            src="./images/logo.png"
                            alt="CEF" />
                    </a>
                </div>
                <div onClick={() => setOpen(!open)}>
                    <Icon icon="gg:menu-round" color="white" width="30" height="30" />
                </div>
            </div>

            <ul className={`md:hidden absolute bg-cefblue2 opacity-100 z-[-1] left-0 w-full pl-6 transition-all duration-500 ease-in ${open ? 'top-25 ':'top-[-600px]'}`}>
            {Links.map((link) => (
                <li key={link.name} className='md:ml-8 text-md md:my-0 my-5'>
                    {link.name === 'Logout' ? (
                        <button onClick={onLogout} className='text-ceftext hover:text-white hover:bg-cefblue hover:p-2 hover:rounded-md duration-500'>
                            {link.name}
                        </button>
                    ) : (
                        <a href={link.link} className='text-ceftext hover:text-white hover:bg-cefblue hover:p-2 hover:rounded-md duration-500'>
                            {link.name}
                        </a>
                    )}
                </li>
            ))}
            </ul>
      </div>
    </>
    );
};

export default Navbar;