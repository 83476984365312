import React, { useEffect, useState} from "react";
import { PieChart, Pie, Cell, ResponsiveContainer } from 'recharts';
import { getAttendanceByCounty } from '../components/services';


/**
 * Displays a pie chart representing age distribution for a given county's attendance data.
 *
 * @param {Object} prop - Component props.
 * @param {string} prop.county - County for which to display the age distribution.
 * @returns {JSX.Element} - React component displaying the age distribution pie chart.
 */
const AgeChart = (prop) => {
    const {county} = prop
    const [data, setData] = useState([]);

    useEffect(() => {
      // Fetch attendance data from Firestore for the given county
      const fetchDataFromFirestore = async () => {
        const attendanceData= await getAttendanceByCounty('attendance', county);

        // Process attendance data into age groups
        const underFiveData = attendanceData.filter((attendance) => attendance.age < '5'); 
        const FiveData = attendanceData.filter((attendance) => attendance.age === '5'); 
        const sixAndsevenData = attendanceData.filter((attendance) => attendance.age === '6' || attendance.age === '7');
        const eightAndnineData = attendanceData.filter((attendance) => attendance.age === '8' || attendance.age === '9');
        const tenAndelevenData = attendanceData.filter((attendance) => attendance.age === '10' || attendance.age === '11');
        const twelveData = attendanceData.filter((attendance) => attendance.age === '12');
        const aboveTwelveData = attendanceData.filter((attendance) => attendance.age >= '12');

        try {
         const fetchedData = [
            { name: "5-", value: underFiveData.length },
            { name: "5", value: FiveData.length },
            { name: "6-7", value: sixAndsevenData.length },
            { name: "8-9", value: eightAndnineData.length },
            { name: "10-11", value: tenAndelevenData.length },
            { name: "12", value: twelveData.length },
            { name: "12+", value: aboveTwelveData.length },
          ];

          // Update state with processed data
          setData(fetchedData);

        } catch (error) {
          console.error("Error fetching data:", error);
        }
      };
  
      fetchDataFromFirestore();
    }, [county]);
      
      const COLORS = ['#FF0000', '#2EB62C', '#2EB62C', '#2EC506', '#3CE203', '#4AFF00', '#CC0000',];
      
      const RADIAN = Math.PI / 180;

      // Render customized labels for pie chart
      const renderCustomizedLabel = ({ cx, cy, midAngle, innerRadius, outerRadius, percent, index, name }) => {
        const radius = innerRadius + (outerRadius - innerRadius) * 0.55;
        const x = cx + radius * Math.cos(-midAngle * RADIAN);
        const y = cy + radius * Math.sin(-midAngle * RADIAN);
    
        return (
            <text x={x} y={y} fill="black" textAnchor="middle" dominantBaseline="central">
                {`${(percent * 100).toFixed(0)}% ${name}`}
            </text>
        );
    };

    return (
        <div className="relative bg-cefblue2 shadow-lg z-30 p-1 rounded-lg">
            <h1 className='text-ceftext text-center py-6 lg:text-3xl lg:font-bold sm:py-2 sm:text-lg'> Age </h1>
            <div className='bg-cefblue2 rounded'>
                <ResponsiveContainer width="100%" height={400}>
                    <PieChart width={100} height={100}>
                        <Pie
                            data={data}
                            cx="50%"
                            cy="50%"
                            labelLine={false}
                            label={({ cx, cy, midAngle, innerRadius, outerRadius, percent, index }) => renderCustomizedLabel({
                                cx,
                                cy,
                                midAngle,
                                innerRadius,
                                outerRadius,
                                percent,
                                index,
                                name: data[index].name // Pass the name of the group from the data array
                            })}
                            outerRadius={180}
                            fill="#8884d8"
                            dataKey="value"
                        >
                            {data.map((entry, index) => (
                                <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
                            ))}
                        </Pie>
                    </PieChart>
                </ResponsiveContainer>
            </div>
        </div>
    );


}; export default AgeChart;