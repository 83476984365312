import { firestore } from "../utils/firebaseConfig";
import { collection, addDoc, doc } from "firebase/firestore";
import React, { useState, useContext, useEffect } from 'react';
import { AuthContext } from "../authContext";
import { getUserData } from "../components/services";

const GnDayCamp = ({ resetForm }) => {
    const { currentUser } = useContext(AuthContext);

    const [userData, setUserData] = useState(null);
    const [newDate, setNewDate] = useState('');
    const [newTime, setNewTime] = useState('');
    const [newPhone, setNewPhone] = useState('');
    const [newAddress, setNewAddress] = useState('')
    const [newCity, setNewCity] = useState('');
    const [newState, setNewState] = useState('');
    const [newZip, setNewZip] = useState('');
    const [newOffering, setNewOffering] = useState('');
    const [newComments, setNewComments] = useState('');

    const docRef = collection(firestore, "goodnewsdaycamp");
    
     useEffect(() => {
        const fetchData = async () => {
        const data = await getUserData(currentUser.uid);
        setUserData(data.data());
        };

        fetchData();
    }, [currentUser]);

    console.log(userData);
    
    const createClub = async (e) => {
        e.preventDefault();

        const userDocRef = doc(firestore, 'users', currentUser.uid);
        console.log(userDocRef);
        await addDoc(docRef, {
            date: newDate,
            time: newTime,
            phone: newPhone,
            address: newAddress,
            city: newCity,
            state: newState,
            zip: newZip,
            offering: newOffering,
            comments: newComments,
            county: userData.county,
            missionary: userDocRef
        });

        console.log('Added document with ID: ', docRef.uid);
        resetForm();
    };
    
     

    return (
        <div className="flex flex-col py-4 items-center justify-center">
    <div className="w-full h-auto shadow-lg flex justify-start flex-col rounded-lg">
        <div className="p-2 w-full border-b dark:border-transparent text-center font-bold">
            <span className="text-xl text-ceftext">Goodnews Camp</span>
        </div>
        <div className="flex flex-col items-center justify-cente border-b">
            <form className="flex place-content-center p-8">
                <div className="grid w-full gap-6 mb-6 md:grid-cols-3 sm:grid-cols-1 md:grid-rows-2">
                    <div>
                        <label htmlFor="Date" className="block mb-2 text-sm font-medium text-ceftext ">Date</label>
                        <input 
                            type="date" 
                            className="bg-cefblue2 border border-gray-300 text-black text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5" 
                            placeholder="Select date"
                            value={newDate}
                            required
                            onChange={(e) => setNewDate(e.target.value)}></input>
                    </div>
                    <div>
                        <label htmlFor="Time" className="block mb-2 text-sm font-medium text-ceftext ">Time</label>
                        <input 
                            type="time" 
                            id="time" 
                            className="bg-cefblue2 border border-gray-300 text-black  text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5" 
                            value={newTime} 
                            required
                            onChange={(e) => setNewTime(e.target.value)}></input>
                    </div>
                    <div>
                        <label htmlFor="Phone" className="block mb-2 text-sm font-medium text-ceftext ">Phone</label>
                        <input 
                            type="tel" 
                            id="phone" 
                            className="bg-cefblue2 border border-gray-300 text-black text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5" 
                            placeholder="215-324-1112" 
                            pattern="[0-9]{3}-[0-9]{3}-[0-9]{4}" 
                            value={newPhone}
                            required
                            onChange={(e) => setNewPhone(e.target.value)}></input>
                    </div>
                    <div className = "col-span-2">
                        <label htmlFor="Address" className="block mb-2 text-sm font-medium text-ceftext ">Address</label>
                        <input 
                            type="address" 
                            id="Address" 
                            className="bg-cefblue2 border border-gray-300 text-black text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5" 
                            value={newAddress} 
                            required
                            onChange={(e) => setNewAddress(e.target.value)}></input>
                    </div>
                    <div>
                        <label htmlFor="City" className="block mb-2 text-sm font-medium text-ceftext">City</label>
                        <input 
                            type="text" 
                            id="City" 
                            className="bg-cefblue2 border border-gray-300 text-black text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5" 
                            value={newCity}
                            required
                            onChange={(e) => setNewCity(e.target.value)}></input>
                    </div>
                    <div>
                        <label htmlFor="State" className="block mb-2 text-sm font-medium text-ceftext ">State</label>
                        <input 
                            type="text" 
                            id="State" 
                            className="bg-cefblue2 border border-gray-300 text-black text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5" 
                            value={newState}
                            required
                            onChange={(e) => setNewState(e.target.value)}></input>
                    </div>
                    <div>
                        <label htmlFor="Zip" className="block mb-2 text-sm font-medium text-ceftext ">Zip</label>
                        <input 
                            type="number" 
                            id="Zip" 
                            className="bg-cefblue2 border border-gray-300 text-black text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5" 
                            value={newZip} 
                            required
                            onChange={(e) => setNewZip(e.target.value)}></input>
                    </div>
                    <div>
                        <label htmlFor="Zip" className="block mb-2 text-sm font-medium text-ceftext ">Total Offering</label>
                        <input 
                            type="number" 
                            id="offering" 
                            className="bg-cefblue2 border border-gray-300 text-black text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5" 
                            value={newOffering} 
                            required
                            onChange={(e) => setNewOffering(e.target.value)}></input>
                    </div>
                    <div className= "row-span-2 col-span-3">
                        <label htmlFor="comments" 
                        className="block mb-2 text-sm font-medium text-ceftext">Addiontial comments:
                        </label>
                        <textarea 
                            id="comments"   
                            rows="4" 
                            className="block p-2.5 w-full text-sm text-black bg-cefblue2 rounded-lg border border-gray-300 focus:ring-blue-500 focus:border-blue-500" 
                            placeholder="Leave a comment..."
                            value={newComments}
                            onChange={(e) => setNewComments(e.target.value)}>
                        </textarea>
                    </div>
                </div>
                <div>                            
                </div>
            </form>
        </div>
        <div className="p-2 w-full min-h-full flex items-center justify-center">
        <button className="bg-transparent hover:bg-cefblue2 hover:text-cefblue text-ceftext font-bold py-2 px-4 rounded-l-lg hover:text-cefpurple"
                type="submit"
                onClick={resetForm}>
                Back to Forms
            </button>
            <button className="bg-transparent hover:bg-cefblue2 hover:text-cefblue text-ceftext font-bold py-2 px-4 rounded-r-lg"
                type="submit"
                onClick={createClub}>
                Upload Form
            </button>
        </div>
    </div>
</div>
    );
  };
  
  export default GnDayCamp;
