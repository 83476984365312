import React, { createContext, useContext, useEffect, useState } from 'react';
import { AuthContext } from '../authContext';
import { getUserData } from './services';

// Create a context
export const UserDataContext = createContext();

// Create a context provider component
export function UserDataProvider({ children }) {
  const { currentUser } = useContext(AuthContext);
  const [userData, setUserData] = useState(null);

  useEffect(() => {
    const fetchUserData = async () => {
      if (currentUser) {
        try {
          const userDataResult = await getUserData(currentUser.uid);
          setUserData(userDataResult.data());
        } catch (error) {
          // Handle error here
        }
      }
    };

    fetchUserData();
  }, [currentUser]);

  return (
    <UserDataContext.Provider value={userData}>
      {children}
    </UserDataContext.Provider>
  );
}
