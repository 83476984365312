import axios from 'axios';

export async function importDataToFirestore(collectionPath, jsonData) {
  try {
    const response = await axios.post('https://us-central1-employee-portal-8f758.cloudfunctions.net/importData', {
      collectionPath,
      jsonData,
    });

    
    console.log('Data import completed.');
    console.log(response.data.message);
  } catch (error) {
    console.error('Error importing data:', error);
  }
}

export async function exportToSheets(firestoreCollectionPath, googleSheetId, title ) {
  try {
    const response = await axios.post('https://us-central1-employee-portal-8f758.cloudfunctions.net/exportFirestoreToSheet' , {
      collectionPath: firestoreCollectionPath,
      sheetId: googleSheetId,
      sheetTitle: title,
    });

    console.log('Function response:', response.data);
  } catch (error) {
    console.error('Error calling Cloud Function:', error);
  }
};

// Function to call the Cloud Function for Google Sign-In
export async function signInWithGoogleCloudFunction() {
  try {
    const response = await axios.get(
      'https://your-cloud-function-url.com/googleSignIn'
    );

    return response.data;
  } catch (error) {
    throw error;
  }
};

export async function checkUserByEmail(emailToCheck) {
  try {
    const cloudFunctionURL = 'https://us-central1-employee-portal-8f758.cloudfunctions.net/checkUserByEmail '; // Replace with the actual URL of your Cloud Function
    const response = await axios.get(`${cloudFunctionURL}?email=${emailToCheck}`);
    
    if (response.data.exists) {
      console.log(`User with email ${emailToCheck} exists.`);
      // You can perform actions for an existing user here
    } else {
      console.log(`User with email ${emailToCheck} does not exist.`);
      // You can handle actions for a non-existing user here
    }
  } catch (error) {
    console.error('Error checking user existence:', error);
  }
};