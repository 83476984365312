import React from "react";
import { BrowserRouter as Router } from "react-router-dom";
import AppContent from './components/AppContent';
import { AuthProvider } from './authContext';
import { UserDataProvider } from "./components/UserDataContext";
import "tailwindcss/tailwind.css"

function App() {
  return (
    <AuthProvider>
      <UserDataProvider>
        <Router>
          <AppContent />
        </Router>
      </UserDataProvider>
    </AuthProvider>
  );
}

export default App;