import React, { useEffect, useState, useContext } from "react";
import { UserDataContext } from "../components/UserDataContext";
import { Icon } from '@iconify/react';
import { getAllUsersByCounty } from "../components/services";

function CreateEvent({teacher}){
    return(
        <div className="mb-2 lg:mb-0 md:mb-2 px-6 md:px-0">
            <div className="block rounded-lg bg-cefblue2 shadow-[0_2px_15px_-3px_rgba(0,0,0,0.07),0_10px_20px_-2px_rgba(0,0,0,0.04)]">
                <div className="relative overflow-hidden bg-cover bg-no-repeat">
                    <img src={teacher.photoUrl} className="w-full rounded-t-lg" alt="Yur"/>
                    <a href="#!">
                        <div className="absolute top-0 right-0 bottom-0 left-0 h-full w-full overflow-hidden bg-fixed"></div>
                    </a>
                    <svg className="absolute text-ceftext dark:text-neutral-700 left-0 bottom-0" xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 1440 320">
                        <path fill="white"
                            d="M0,288L48,272C96,256,192,224,288,197.3C384,171,480,149,576,165.3C672,181,768,235,864,250.7C960,267,1056,245,1152,250.7C1248,256,1344,288,1392,304L1440,320L1440,320L1392,320C1344,320,1248,320,1152,320C1056,320,960,320,864,320C768,320,672,320,576,320C480,320,384,320,288,320C192,320,96,320,48,320L0,320Z">
                        </path>
                    </svg>
                </div>
                <div className="p-4 bg-white">
                    <h5 className="mb-4 text-lg font-bold">{teacher.firstName} {teacher.lastName}</h5>
                    <p className="mb-4 text-neutral-500">{teacher.roleDesc}</p>
                    <ul className="mx-auto flex list-inside justify-center">
                        <a href="#!" className="px-2">
                            {/*  Email */}
                            <Icon icon="ep:chat-dot-round" />
                        </a>
                        <a href="#!" className="px-2">
                            {/*  Phone */}
                            <Icon icon="ep:phone" />
                        </a>
                    </ul>
                </div>
            </div>
        </div>
    )
};

function LoadTeachers() {
    const [data, setData] = useState([]);
    const userData  = useContext(UserDataContext);
    
  useEffect(() => {
      if (userData) {
          const fetchData = async () => {
              try {
                  const result = await getAllUsersByCounty(userData?.county);
                  setData(result);
              } catch (error) {
                  console.error("Error fetching data:", error);
              }
          };
  
          fetchData();
      }
  }, [userData]);

    return (
        <div className="grid gap-x-8 gap-y-2 md:grid-cols-2 lg:grid-cols-3 lg:gap-x-12 md:gap-y-2 lg:gap-y-8">
            {data.map((teacher) => (
                <CreateEvent key={teacher.id} teacher={teacher} />
            ))}
        </div>
    ); 
}


export default function ShowTeachers() {

    return (
        <div className="container my-24 mx-auto md:px-6">
        {/*  Section: Design Block */}
        <section className="mb-32 text-center">
          <h2 className="mb-12 text-3xl text-ceftext font-bold">
            Teachers
          </h2>       
            <LoadTeachers/>
        </section>
        {/*  Section: Design Block */}
      </div>
    )
}