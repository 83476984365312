import React, { useState } from "react";
import SimpleDropdown from '../helper/simpleDropdown';
import FiveDayClub from '../forms/fiveDayClub';
import GnDayCamp from "../forms/gnDayCamp";
import TeacherSlips from "../forms/teacherSlips";

const Forms = () => {
  const [selectedForm, setSelectedForm] = useState(null);

  const handleFormSelect = (formType) => {
    setSelectedForm(formType);
  };

  const resetForm = () => {
    setSelectedForm(null);
  };

  const renderSelectedForm = () => {
    switch (selectedForm) {
      case "form1":
        return <FiveDayClub resetForm={resetForm} />;
      case "form2":
        return <GnDayCamp resetForm={resetForm} />;
      case "form3":
        return <TeacherSlips resetForm={resetForm} />;
      default:
        return null;
    }
  };

  return (
    <div className="flex flex-col items-center justify-center w-full xs:px-2 sm:px-4 md:px-6 py-10">
      <div className="max-w-6xl mx-auto">
        <h1 className="font-semibold text-3xl sm:text-5xl lg:text-5xl tracking-tight text-center">
          <span className="font-bold bg-clip-text text-transparent bg-cefgrey">Ministry Forms</span>
        </h1>
      </div>
      <div className="mt-4">
        {!selectedForm ? (
          <SimpleDropdown onFormSelect={handleFormSelect} />
        ) : (
          <div>
            {renderSelectedForm()}
          </div>
        )}
      </div>
    </div>
  );
};

export default Forms;
