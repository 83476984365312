import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { Icon } from '@iconify/react';
import { getEventByCountyFitered } from '../../components/services';

const Camp = (props) => {
    const { county } = props; // Add county state variable
    const [clubs, setClubs] = useState([]);
    const [displayedClubs, setDisplayedClubs] = useState([]);
    const [sortField, setSortField] = useState('date');
    const [sortOrder, setSortOrder] = useState('asc');
    const [limitValue, setLimitValue] = useState(10);
    const [pageNumber, setPageNumber] = useState(1);
    const [searchValue, setSearchValue] = useState('');
    const [collectionName] = useState('goodnewsdaycamp');

    useEffect(() => {
        const fetchData = async () => {
        const camps = await getEventByCountyFitered(collectionName, sortField, sortOrder, county); // Use county state variable

        setClubs(camps);
        };

        fetchData();
    }, [sortField, sortOrder, collectionName, county]);

    useEffect(() => {

        const sortClubs = (clubs) => {
        const sortedClubs = [...clubs];

        sortedClubs.sort((a, b) => {
            if (a[sortField] < b[sortField]) {
              return sortOrder === 'asc' ? -1 : 1;
            } else if (a[sortField] > b[sortField]) {
              return sortOrder === 'asc' ? 1 : -1;
            } else {
                return 0;
            }
        });

            return sortedClubs;
        };
        
        const searchValueLower = searchValue.toLowerCase().trim();
        let filteredClubs = clubs;

        if (searchValueLower) {
            filteredClubs = clubs.filter(club =>
            club.date.toLowerCase().includes(searchValueLower) ||
            club.address.toLowerCase().includes(searchValueLower) ||
            club.time.toLowerCase().includes(searchValueLower) ||
            club.missionaryName?.toLowerCase().includes(searchValueLower)
        );
        }

        const sortedClubs = sortClubs(filteredClubs);
        setDisplayedClubs(sortedClubs.slice((pageNumber - 1) * limitValue, pageNumber * limitValue));
        }, [clubs, limitValue, pageNumber, searchValue, sortField, sortOrder]);

        const handleSort = (field) => {

            if (sortField === field) {
                setSortOrder(sortOrder === 'asc' ? 'desc' : 'asc');
            } else {
                setSortField(field);
                setSortOrder('asc');
            }
        };

        const handleLimitValue = (event) => {
            setLimitValue(Number(event.target.value));
            setPageNumber(1);
        };

        const handlePrevPage = () => {
            setPageNumber(pageNumber - 1);
        };

        const handleNextPage = () => {
            setPageNumber(pageNumber + 1);
        };

        const handleSearchValue = (event) => {
            setSearchValue(event.target.value);
            setPageNumber(1);
        };

    const start = (pageNumber - 1) * limitValue;
    const end = pageNumber * limitValue;


return (

<div className="container mx-auto p-10 bg-cefaqua rounded-lg xs:p-4 sm:p-6 md:p-6 lg:p-8 xl:p-15 ">
            <form>   
                <label htmlFor="search" className="mb-2 text-sm font-medium text-ceftext sr-only">Search</label>
                <div className="relative">
                    <div className="absolute inset-y-0 left-0 flex items-center pl-3 pointer-events-none">
                    <Icon icon="raphael:search" />
                    </div>
                    <input 
                        type="search" 
                        id="search" 
                        className="block w-full p-2 pl-10 text-sm border border-gray-300 rounded-lg bg-gray-50 focus:ring-blue-500 focus:border-blue-500" 
                        placeholder="Search by Hostess, Date, Address or Time"
                        value={searchValue}
                        onChange={handleSearchValue}/>
                </div>
            </form>  
            <div className="flex justify-between items-center mb-6 pt-4">
                <h1 className="text-3xl text-ceftext font-semibold">Good News Camp</h1>
                <form onSubmit={event => event.preventDefault()}>
                </form>
            </div>
                <table className="table-auto w-full text-sm text-left text-gray-500">
                    <thead className="text-sm text-gray-600 bg-gray-50">
                        <tr className="bg-cefblue2 border-b">
                            <th scope="col"
                                className="px-6 py-3 hover:text-blue-500 cursor-pointer"
                                onClick={() => handleSort('date')}
                            >
                                Date
                            </th>
                            <th scope="col"
                                className="px-6 py-3"
                            >
                              Offering  
                            </th>
                            <th scope="col"
                                className="px-6 py-3"
                            >
                                Time
                            </th>
                            <th scope="col"
                                className="px-6 py-3"
                            >
                                Phone
                            </th>
                            <th scope="col"
                                className="px-6 py-3"
                            >
                                Address
                            </th>
                            <th scope="col"
                                className="px-6 py-3"
                            >
                                City
                            </th>
                            <th scope="col"
                                className="px-6 py-3"
                            >
                                State
                            </th>
                            <th scope="col"
                                className="px-6 py-3 hover:text-blue-500 cursor-pointer"
                                onClick={() => handleSort('zip')}
                            >
                                Zip
                            </th>
                            <th scope="col"
                                className="md:hidden lg:flex px-6 py-3"
                            >
                                Comments
                            </th>
                            <th scope="col"
                                className="px-6 py-3"
                            >
                                Attn
                            </th>
                            <th scope="col"
                                className="px-6 py-3"
                            >
                                Edit
                            </th>
                        </tr>
                    </thead>
                    <tbody>
                        {displayedClubs.map((club) => (
                        <tr key={club.id} className="bg-cefblue2">
                            <td className="px-6 py-4  text-ceftext">{club.date}</td>
                            <td className="px-6 py-4  text-ceftext">{club.offering}</td>
                            <td className="px-6 py-4  text-ceftext">{club.time}</td>
                            <td className="px-6 py-4  text-ceftext">{club.phone}</td>
                            <td className="px-6 py-4  text-ceftext">{club.address}</td>
                            <td className="px-6 py-4  text-ceftext">{club.city}</td>
                            <td className="px-6 py-4  text-ceftext">{club.state}</td>
                            <td className="px-6 py-4  text-ceftext">{club.zip}</td>
                            <td className="md:hidden lg:flex px-6 py-4  text-ceftext">{club.comments}</td>
                            <td className="px-6 py-4  text-ceftext">
                            <Link to={`/attendance/${club.id}?collectionName=${collectionName}`} key={club.id}
                                className="hover:text-red-700 object-fill"><Icon icon="tabler:eye-search" width="30" height="30" /></Link>
                            </td>
                            <td className="px-6 py-4  text-ceftext">
                                <Link to={`/editforms/${club.id}`} key={club.id} 
                                className="hover:text-red-700 object-fill"><Icon icon="tabler:eye-edit" width="30" height="30" /></Link>
                            </td>
                        </tr>
                        ))}
                    </tbody>
                </table>
                <div className="flex justify-between items-center text-ceftext mt-6">
                    <div>
                        Showing {start + 1} to {Math.min(end, clubs.length)} of {clubs.length} clubs
                    </div>
                    <div className="flex items-center">
                    <button
                        className="flex items-center bg-gray-200  text-black rounded-l-md border-r border-gray-100 py-2 hover:bg-red-700 hover:text-ceftext px-3"
                        onClick={handlePrevPage}
                        disabled={pageNumber === 1}
                    >
                        <Icon icon="material-symbols:arrow-circle-left" />
                        <span className="ml-2 text-sm font-medium">Prev</span>
                    </button>
                    <button
                        className="flex items-center bg-gray-200  text-black rounded-r-md border-r border-gray-100 py-2 hover:bg-red-700 hover:text-ceftext px-3"
                        onClick={handleNextPage}
                        disabled={end >= clubs.length}
                    >
                        <span className="mr-2 text-sm font-medium">Next</span>
                        <Icon icon="material-symbols:arrow-circle-right" />
                    </button>
                    <div className="ml-2">
                        {pageNumber}/{Math.ceil(clubs.length / limitValue)}
                    </div>
                    <div className="ml-2">
                            <select value={limitValue} onChange={handleLimitValue} className="bg-gray-200 text-black rounded text-sm font-medium">
                            <option value={10}>10 per page</option>
                            <option value={15}>15 per page</option>
                            <option value={20}>20 per page</option>
                        </select>
                    </div>
                </div>
            </div>
        </div>
)


}; export default Camp;