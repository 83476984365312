import React, { useState, useEffect, useContext } from 'react';
import { collection, doc, getDoc, setDoc, updateDoc } from 'firebase/firestore';
import { AuthContext } from '../authContext';
import { useParams, useLocation } from 'react-router-dom';
import { Icon } from '@iconify/react';
import { firestore } from '../utils/firebaseConfig';
import { getAttendanceByEvent, GetTotaChurched, GetTotalDay, getAttendancePerEvent, GetTotalProf, GetTotalAss } from '../components/services';

const Attendance = () => {
  const { id  } = useParams();
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const collectionName = queryParams.get('collectionName');
  const [attn, setAttn] = useState([]);
  const [kids, setKids] = useState();
  const [church, setChurch] = useState();
  const [mon, setMon] = useState();
  const [tues, setTues] = useState();
  const [wed, setWed] = useState();
  const [thur, setThur] = useState();
  const [fri, setFri] = useState();
  const [prof, setProf] = useState();
  const [ass, setAss] = useState();
  const [newAttn, setNewAttn] = useState({});
  const [originalAttnData, setOriginalAttnData] = useState({});
  const { currentUser } = useContext(AuthContext);

  useEffect(() => {

      const fetchAttendance = async () => {
        try{
            const data = await getAttendanceByEvent(collectionName, id)
            setAttn(data);
            setOriginalAttnData(data);
        } catch (error) {
            console.error('Error fetching attendance:', error);
        }


      };

    const fetchAttendancePerClub = async () => {
        try {
          const attendancePerClub = await getAttendancePerEvent(id, collectionName);
          setKids(attendancePerClub);
        } catch (error) {
          console.error('Error fetching attendance per club:', error);
        }
      };

    const fetchGetTotals = async () => {
    try {
        const totalDay = await GetTotalDay(id, 'monday', collectionName);
        setMon(totalDay);
    } catch (error) { 
        console.error('Error fetching attendance on monday:', error);
    }
    try {
        const totalDay = await GetTotalDay(id, 'tuesday', collectionName);
        setTues(totalDay);
    } catch (error) { 
        console.error('Error fetching attendance on tuesday:', error);
    }
    try {
        const totalDay = await GetTotalDay(id, 'wednesday', collectionName);
        setWed(totalDay);
    } catch (error) { 
        console.error('Error fetching attendance on wednesday:', error);
    }
    try {
        const totalDay = await GetTotalDay(id, 'thursday', collectionName);
        setThur(totalDay);
    } catch (error) { 
        console.error('Error fetching attendance on thursday:', error);
    }
    try {
        const totalDay = await GetTotalDay(id, 'friday', collectionName);
        setFri(totalDay);
    } catch (error) { 
        console.error('Error fetching attendance on friday:', error);
    }
    try {
        const totalDay = await GetTotaChurched(id, collectionName);
        setChurch(totalDay);
    } catch (error) { 
        console.error('Error fetching attendance on church:', error);
    }
    try {
        const totalDay = await GetTotalProf(id, collectionName);
        setProf(totalDay);
    } catch (error) { 
        console.error('Error fetching attendance on church:', error);
    }
    try {
        const totalDay = await GetTotalAss(id, collectionName);
        setAss(totalDay);
    } catch (error) { 
        console.error('Error fetching attendance on church:', error);
    }

    }; 

    fetchGetTotals();
    fetchAttendancePerClub();
    fetchAttendance();
  }, [id, currentUser, collectionName]);

  const handleFieldChange = (event, rowId, fieldName) => {
    const value = event.target.type === 'checkbox' ? event.target.checked : event.target.value;

    const updatedRows = attn.map(row => {
      if (row.id === rowId && row.editable) {
        return { ...row, [fieldName]: value };
      }
      return row;
    });

    setAttn(updatedRows);
  };

  const handleNewFieldChange = (event, fieldName, checkboxValue = null) => {
    const value = checkboxValue !== null ? checkboxValue : event.target.value;
    setNewAttn({ ...newAttn, [fieldName]: value });
  };

  const handleUpload = async () => {
    const clubRef = doc(firestore, collectionName, id);
    const clubDoc = await getDoc(clubRef);
    const clubData = clubDoc.data();
  
    const attendanceRef = collection(clubRef, 'attendance');
    const newAttnDocRef = doc(attendanceRef);
  
    // Create a new attendance object with the "date" field taken from the club document
    let newAttnWithDate = {
      ...newAttn, // Make sure "newAttn" is defined somewhere in your code
      date: clubData.date,
      county: clubData.county
    };
  
    // Conditionally add fields based on collectionName
    if (collectionName === 'goodnewsdaycamp') {
      newAttnWithDate = {
        ...newAttnWithDate,
        camp: true
      };
    } else if (collectionName === 'goodnewsclub') {
      newAttnWithDate = {
        ...newAttnWithDate,
        gnclub: true
      };
    }
  
    await setDoc(newAttnDocRef, newAttnWithDate);
  
    setAttn([...attn, { ...newAttnWithDate, id: newAttnDocRef.id }]);
    setNewAttn({ name: '', age: '', guardian: '', address: '', church: false, monday: false, 
        tuesday: false, wednesday: false, thursday: false, friday:false, profession: false, assurance:false });
  };

  const handleSave = async () => {
    if (!id || id === "") {
      console.error("Error: Invalid ID");
      return;
    }
  
    const clubRef = doc(firestore, collectionName, id);
    const attendanceRef = collection(clubRef, "attendance");
  
    // Filter out rows that were not modified
    const modifiedRows = attn.filter((row) => {
      const originalRow = originalAttnData.find((r) => r.id === row.id);
      return (
        row.name !== originalRow.name ||
        row.age !== originalRow.age ||
        row.guardian !== originalRow.guardian ||
        row.address !== originalRow.address ||
        row.church !== originalRow.church ||
        row.monday !== originalRow.monday ||
        row.tuesday !== originalRow.tuesday ||
        row.wednesday !== originalRow.wednesday ||
        row.thursday !== originalRow.thursday ||
        row.friday !== originalRow.friday ||
        row.profession !== originalRow.profession ||
        row.assurance !== originalRow.assurance
      );
    });
  
    // Create an object with defined values for each field
    const newAttendance = modifiedRows.map((row) => ({
      ...row,
      name: row.name ?? "",
      age: row.age ?? "",
      guardian: row.guardian ?? "",
      address: row.address ?? "",
      church: row.church ?? false,
      monday: row.monday ?? false,
      tuesday: row.tuesday ?? false,
      wednesday: row.wednesday ?? false,
      thursday: row.thursday ?? false,
      friday: row.friday ?? false,
      profession: row.profession ?? false,
      assurance: row.assurance ?? false,
    }));

    // Write the new data to Firestore
    await Promise.all(
      newAttendance.map(async (row) => {
        const rowRef = doc(attendanceRef, row.id);
        await updateDoc(rowRef, row);
      })
    );
  
    // Update state with the new data
    // Update the editable property for each row to false
    const updatedAttn = attn.map(row => ({ ...row, editable: false }));

    // Update state with the new data and set all rows to non-editable
    setAttn(updatedAttn);
  };

 /* const handleDelete = async (rowId) => {
    if (!id || id === "") {
        console.error("Error: Invalid ID");
        return;
      }

    const clubRef = doc(firestore, "collectionName", id);
    const attendanceRef = collection(clubRef, "attendance", rowId);
}*/

const handleToggleEdit = rowId => {
    const updatedRows = attn.map(row => {
      if (row.id === rowId) {
        return { ...row, editable: !row.editable };
      }
      return row;
    });

    setAttn(updatedRows);
  };
  
  

  return (
    <div>
        <div className="container flex flex-col items-center justify-center text-center mx-auto p-8 xs:p-4 sm:p-6 md:p-6 lg:p-8 xl:p-15 ">
            <div className='flex flex-wrap'>
                <h2 className="text-ceftext text-l font-extrabold px-2">Totals Kids: {kids} </h2>
                <h2 className="text-ceftext text-l font-extrabold px-2">Church: {church}</h2>
                <h2 className="text-ceftext text-l font-extrabold px-2">Mon: {mon}</h2>
                <h2 className="text-ceftext text-l font-extrabold px-2">Tues: {tues}</h2>
                <h2 className="text-ceftext text-l font-extrabold px-2">Wed: {wed}</h2>
                <h2 className="text-ceftext text-l font-extrabold px-2">Thur: {thur}</h2>
                <h2 className="text-ceftext text-l font-extrabold px-2">Fri: {fri}</h2>
                <h2 className="text-ceftext text-l font-extrabold px-2">Prof: {prof}</h2>
                <h2 className="text-ceftext text-l font-extrabold px-2">Ass: {ass}</h2> 
            </div> 
        </div>
        <div className="table-container flex justify-center items-center overflow-x-auto p-8 xs:p-4 sm:p-6 md:p-6 lg:p-8 xl:p-15">
            <div className='w-full overflow-x-auto lg:w-auto'>
                <table className="table-auto text-sm text-left text-ceftext">
                    <thead className="text-sm bg-gray-50">
                        <tr className="bg-cefblue2 border-b ">
                            <th className="px-3 py-3 cursor-pointer">Name</th>
                            <th className="px-1 py-3 cursor-pointer">Age</th>
                            <th className="px-1 py-3 cursor-pointer">Lives With(Adult)</th>
                            <th className="px-1 py-3 cursor-pointer">Address</th>
                            <th className="px-1 py-3 cursor-pointer">Church</th>
                            <th className="px-1 py-3 cursor-pointer">M</th>
                            <th className="px-1 py-3 cursor-pointer">T</th>
                            <th className="px-1 py-3 cursor-pointer">W</th>
                            <th className="px-1 py-3 cursor-pointer">T</th>
                            <th className="px-1 py-3 cursor-pointer">F</th>
                            <th className="px-1 py-3 cursor-pointer">P</th>
                            <th className="px-1 py-3 cursor-pointer">A</th>
                        </tr>
                    </thead>
                    <tbody>
                        {attn.map((row) => (
                            <tr key={row.id} className="">
                                <td className="bg-cefblue2 px-3 py-3  text-ceftext">
                                    {row.editable ? (
                                        <input
                                            type="text"
                                            defaultValue={row.name}
                                            size="4"
                                            onChange={(event) => handleFieldChange(event, row.id, "name")} />
                                    ) : (
                                        row.name
                                    )}
                                </td>
                                <td className="bg-cefblue2 px-1 py-3  text-ceftext">
                                    {row.editable ? (
                                        <input
                                            type="number"
                                            defaultValue={row.age}
                                            size="1"
                                            onChange={(event) => handleFieldChange(event, row.id, "age")} />
                                    ) : (
                                        row.age
                                    )}
                                </td>
                                <td className="bg-cefblue2 px-1 py-2  text-ceftext">
                                    {row.editable ? (
                                        <input
                                            type="text"
                                            defaultValue={row.guardian}
                                            size="5"
                                            onChange={(event) => handleFieldChange(event, row.id, "guardian")} />
                                    ) : (
                                        row.guardian
                                    )}
                                </td>
                                <td className="bg-cefblue2 px-6 py-2  text-ceftext">
                                    {row.editable ? (
                                        <input
                                            type="text"
                                            defaultValue={row.address}
                                            onChange={(event) => handleFieldChange(event, row.id, "address")} />
                                    ) : (
                                        row.address
                                    )}
                                </td>
                                <td className="bg-cefblue2 px-1 py-2  text-ceftext">
                                    {row.editable ? (
                                        <input
                                            type="checkbox"
                                            checked={row.church}
                                            onChange={(event) => handleFieldChange(event, row.id, "church")} />
                                    ) : row.church ? (
                                            "Yes"
                                        ) : (
                                            "No"
                                        )}
                                </td>
                                <td className="bg-cefblue2 px-1 py-2  text-ceftext">
                                    {row.editable ? (
                                        <input
                                            type="checkbox"
                                            checked={row.monday}
                                            onChange={(event) => handleFieldChange(event, row.id, "monday")} />
                                    ) : row.monday ? (
                                        "Yes"
                                    ) : (
                                        "No"
                                    )}
                                </td>
                                <td className="bg-cefblue2 px-1 py-2  text-ceftext">
                                    {row.editable ? (
                                        <input
                                            type="checkbox"
                                            checked={row.tuesday}
                                            onChange={(event) => handleFieldChange(event, row.id, "tuesday")} />
                                    ) : row.tuesday ? (
                                        "Yes"
                                    ) : (
                                        "No"
                                    )}
                                </td>
                                <td className="bg-cefblue2 px-1 py-2  text-ceftext">
                                    {row.editable ? (
                                        <input
                                            type="checkbox"
                                            checked={row.wednesday}
                                            onChange={(event) => handleFieldChange(event, row.id, "wednesday")} />
                                    ) : row.wednesday ? (
                                        "Yes"
                                    ) : (
                                        "No"
                                    )}
                                </td>
                                <td className="bg-cefblue2 px-1 py-2  text-ceftext">
                                    {row.editable ? (
                                        <input
                                            type="checkbox"
                                            checked={row.thursday}
                                            onChange={(event) => handleFieldChange(event, row.id, "thursday")} />
                                    ) : row.thursday ? (
                                        "Yes"
                                    ) : (
                                        "No"
                                    )}
                                </td>
                                <td className="bg-cefblue2 px-1 py-2  text-ceftext">
                                    {row.editable ? (
                                        <input
                                            type="checkbox"
                                            checked={row.friday}
                                            onChange={(event) => handleFieldChange(event, row.id, "friday")} />
                                    ) : row.friday ? (
                                        "Yes"
                                    ) : (
                                        "No"
                                    )}
                                </td>
                                <td className="bg-cefblue2 px-1 py-2  text-ceftext">
                                    {row.editable ? (
                                        <input
                                            type="checkbox"
                                            checked={row.profession}
                                            onChange={(event) => handleFieldChange(event, row.id, "profession")} />
                                    ) : row.profession ? (
                                        "Yes"
                                    ) : (
                                        "No"
                                    )}
                                </td>
                                <td className="bg-cefblue2 px-1 py-2  text-ceftext">
                                    {row.editable ? (
                                        <input
                                            type="checkbox"
                                            checked={row.assurance}
                                            onChange={(event) => handleFieldChange(event, row.id, "assurance")} />
                                    ) : row.assurance ? (
                                        "Yes"
                                    ) : (
                                        "No"
                                    )}
                                </td>

                                <td>
                                    <button
                                        onClick={() => handleToggleEdit(row.id)}
                                        className="bg-blue-500 hover:bg-red-700 text-ceftext font-bold py-2 px-4 rounded"
                                    >
                                        {row.editable ? <Icon icon="tabler:pencil-cancel" /> : <Icon icon="tabler:pencil" />}
                                    </button>
                                </td>
                            </tr>
                        ))}
                        <tr className="bg-cefblue2">
                            <td className="px-6 py-2  text-ceftext">
                                <input
                                    type="text"
                                    value={newAttn.name}
                                    size="5"
                                    required
                                    onChange={event => handleNewFieldChange(event, 'name')} />
                            </td>
                            <td className="px-1 py-2  text-ceftext">
                                <input
                                    type="number"
                                    value={newAttn.age}
                                    required
                                    onChange={event => handleNewFieldChange(event, 'age')} />
                            </td>
                            <td className="px-1 py-2  text-ceftext">
                                <input
                                    type="text"
                                    value={newAttn.guardian}
                                    size="20"
                                    onChange={event => handleNewFieldChange(event, 'guardian')} />
                            </td>
                            <td className="px-1 py-2  text-ceftext">
                                <input
                                    type="text"
                                    value={newAttn.address}
                                    size="30"
                                    onChange={event => handleNewFieldChange(event, 'address')} />
                            </td>
                            <td className="px-1 py-2  text-ceftext">
                                <input
                                    type="checkbox"
                                    checked={newAttn.church}
                                    onChange={event => handleNewFieldChange(event, 'church', event.target.checked)} />
                            </td>
                            <td className="px-1 py-2  text-ceftext">
                                <input
                                    type="checkbox"
                                    checked={newAttn.monday}
                                    onChange={(event) => handleNewFieldChange(event, 'monday', event.target.checked)} />
                            </td>
                            <td className="px-1 py-2  text-ceftext">
                                <input
                                    type="checkbox"
                                    checked={newAttn.tuesday}
                                    onChange={event => handleNewFieldChange(event, 'tuesday', event.target.checked)} />
                            </td>
                            <td className="px-1 py-2  text-ceftext">
                                <input
                                    type="checkbox"
                                    checked={newAttn.wednesday}
                                    onChange={event => handleNewFieldChange(event, 'wednesday', event.target.checked)} />
                            </td>
                            <td className="px-1 py-2  text-ceftext">
                                <input
                                    type="checkbox"
                                    checked={newAttn.thursday}
                                    onChange={event => handleNewFieldChange(event, 'thursday', event.target.checked)} />
                            </td>
                            <td className="px-1 py-2  text-ceftext">
                                <input
                                    type="checkbox"
                                    checked={newAttn.friday}
                                    onChange={event => handleNewFieldChange(event, 'friday', event.target.checked)} />
                            </td>
                            <td className="px-1 py-2  text-ceftext">
                                <input
                                    type="checkbox"
                                    checked={newAttn.profession}
                                    onChange={event => handleNewFieldChange(event, 'profession', event.target.checked)} />
                            </td>
                            <td className="px-1 py-2  text-ceftext">
                                <input
                                    type="checkbox"
                                    checked={newAttn.assurance}
                                    onChange={event => handleNewFieldChange(event, 'assurance', event.target.checked)} />
                            </td>
                        </tr>
                    </tbody>
                    <tfoot>
                        <tr>
                            <td colSpan="4">
                                <div className="flex justify-between mt-4">
                                    <div className="flex justify-center">
                                        <button
                                            className="bg-green-500 hover:bg-green-700 text-ceftext font-bold py-2 px-4 rounded"
                                            onClick={handleUpload}
                                        >
                                            Add Child/Childeren
                                        </button>
                                    </div>
                                    <div className="flex justify-end">
                                        <button
                                            className="mr-2 bg-blue-500 hover:bg-blue-700 text-ceftext font-bold py-2 px-4 rounded"
                                            onClick={handleSave}
                                        >
                                            Save
                                        </button>
                                    </div>
                                </div>
                            </td>
                        </tr>
                    </tfoot>
                </table>
            </div>
        </div>
    </div>
  );  
}

export default Attendance;
