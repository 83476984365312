import React, { useState, useEffect, useContext } from 'react';
import Papa from 'papaparse';
import { importDataToFirestore, exportToSheets } from '../components/cloudeServices';
import { UserDataContext } from "../components/UserDataContext";
import { fetchDataFromPath } from '../components/services';

const Import = () => {
  const  userData  = useContext(UserDataContext);
  const [isPopupVisible, setIsPopupVisible] = useState(false);
  const [events, setEvents] = useState([]);
  const [inputValues, setInputValues] = useState({
    googleSheetId: '',
    title: '',
  });

  const programOptions = [
    { display: '5-Day Club', value: 'fivedayclubs' },
    { display: 'Good News Clubs', value: 'goodnewsclubs' },
    { display: 'Camps', value: 'goodnewsdaycamp' },
    { display: 'Training', value: 'trainingclass' },
  ];

  const yearOptions = [
    { display: '2022-2023', value: '2022-2023' },
    { display: '2023-2024', value: '2023-2024' },
    { display: '2024-2025', value: '2024-2025' },
    // Add more year options here
  ];


  const [otherOption, setOtherOption] = useState([])
  const [selectedProgram, setSelectedProgram] = useState('');
  const [selectedYear, setSelectedYear] = useState('');
  const [selectedItem, setSelectedItem] = useState('');
  const [selectedOption, setSelectedOption] = useState('');
  const [collectionPath, setCollectionPath] = useState('');
  const [loadingUserData, setLoadingUserData] = useState(true);

  useEffect(() => {
    // Update collectionPath whenever options change
    if (userData) {
      setCollectionPath(`/${selectedProgram}/${userData.county}/${selectedYear}/${selectedItem}`);
      setLoadingUserData(false);
    }

    if(selectedItem) {
      setCollectionPath(`/${selectedProgram}/${userData.county}/${selectedYear}/${selectedItem}/${selectedOption}`);
    }

  }, [selectedProgram, selectedYear, selectedItem, selectedOption, userData]);

  // Event handlers for dropdown changes
  const handleProgramChange = (event) => {
    setSelectedProgram(event.target.value);
    setSelectedYear('');
    setSelectedItem('');
    setCollectionPath('');

    const updatedOtherOption = [];

    if (event.target.value === 'fivedayclubs') {
      updatedOtherOption.push({ display: 'Attendance', value: 'attendance' });
    }

    if (event.target.value === 'trainingclass') {
      updatedOtherOption.push({ display: 'Teachers', value: 'teachers' });
    }

    setOtherOption(updatedOtherOption);
  };

  const handleYearChange = (event) => {
    setSelectedYear(event.target.value);
    setSelectedItem('');
  };

  const handleItemChange =  (event) => {
      setSelectedItem(event.target.value);
      setSelectedOption('');
  };

  const handleOtherChange = (event) => {
    setSelectedOption(event.target.value);
   // setCollectionPath(`/${selectedProgram}/${userData.county}/${selectedYear}/${selectedItem}/${event.target.value}`);
  }

  useEffect(() => {
    // Log the updated collectionPath whenever it changes
    console.log(collectionPath);

  }, [collectionPath]);

   // Function to handle opening the popup
  const handleSheetsClick = () => {
    setIsPopupVisible(true);
  };

  // Function to handle closing the popup
  const handleClosePopup = () => {
    setIsPopupVisible(false);
  };

  // Function to handle input changes
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setInputValues({
      ...inputValues,
      [name]: value,
    });
  };

  // Function to handle form submission
  const handleFormSubmit = () => {
    // Call the exportToSheets function here with inputValues
    exportToSheets(collectionPath, inputValues.googleSheetId, inputValues.title);
    setIsPopupVisible(false); // Close the popup after submission
  };

  const handleImportClick = async (e) => {
    const file = e.target.files[0];
  
    if (!file) {
      console.error('Please select a CSV file.');
      return;
    }
  
    // Check the file type based on its extension
    const fileType = file.name.split('.').pop().toLowerCase();
  
    if (fileType !== 'csv') {
      console.error('Unsupported file type. Please select a CSV file.');
      return;
    }
  
    // Parse the CSV file into JSON format on the client-side
    try {
      const jsonData = await parseCSVFile(file);
      console.log('Parsed JSON data:', jsonData);
  
      // Send the JSON data to your Cloud Function for Firestore import
      await importDataToFirestore(collectionPath, jsonData);
    } catch (error) {
      console.error('Error parsing CSV file:', error);
    }
  };
  
  const parseCSVFile = (file) => {
    return new Promise((resolve, reject) => {
      Papa.parse(file, {
        header: true,
        dynamicTyping: true,
        skipEmptyLines: 'greedy', // Skip rows with missing values
        transformHeader: (header) => header.trim(), // Trim whitespace from headers
        transform: (value) => (value === null || value === undefined ? "" : value.toString().trim()), // Set empty values to ""
        complete: (result) => {
          if (result.errors.length > 0) {
            reject(result.errors[0]);
          } else {
            resolve(result.data);
          }
        },
      });
    });
  };

  if (loadingUserData) {
    return <p>Loading user data...</p>;
  }
  
  const fetchData = async () => {
    setSelectedOption('');
      try {
        const eventList = await fetchDataFromPath(collectionPath);
        setEvents(eventList);
      } catch (error) {
        console.error(error);
      }
  }

  return (
<div className="flex items-center justify-center h-screen flex-col">
  <div className="flex">
    <select
      className="p-2 border rounded"
      value={selectedProgram}
      onChange={handleProgramChange}
    >
      <option value="">Select Program</option>
      {programOptions.map((option) => (
        <option key={option.value} value={option.value}>
          {option.display}
        </option>
      ))}
    </select>

    {selectedProgram && (
      <select
        className="p-2 border rounded"
        value={selectedYear}
        onChange={handleYearChange}
      >
        <option value="">Select Year</option>
        {yearOptions.map((option) => (
          <option key={option.value} value={option.value}>
            {option.display}
          </option>
        ))}
      </select>
    )}

    {selectedYear && (
      <select
        className="p-2 border rounded"
        value={selectedItem}
        onChange={handleItemChange}
        onClick={fetchData}
      >
        <option value="">Select Event</option>
        {events.map((option) => (
          <option key={option.id} value={option.id}>
            {option.hostess} {option.date} 
          </option>
        ))}
      </select>
    )}

    {selectedItem && (
      <select
        className="p-2 border rounded"
        value={selectedOption}
        onChange={handleOtherChange}
      >
        <option value="">Select Item</option>
        {otherOption.map((option) => (
          <option key={option.value} value={option.value}>
            {option.display}
          </option>
        ))}
      </select>
    )}
  </div>

  <div>
    <input
      type="file"
      accept=".csv"
      name="csvFile"
      id="csvFile"
      onChange={handleImportClick}
      className="hidden"
    />
    <label
      htmlFor="csvFile"
      className="block w-full bg-green-800 hover:bg-red-800 mt-4 py-2 px-4 rounded-2xl text-ceftext text-center font-semibold mb-2 cursor-pointer"
    >
      Import CSV File
    </label>
  </div>
  <div>
    <button className="block w-full bg-green-800 hover:bg-red-800 mt-4 py-2 px-4 rounded-2xl text-ceftext text-center font-semibold mb-2 cursor-pointer"
            onClick={handleSheetsClick}>Export to Sheets</button>
  </div>
  {isPopupVisible && (
        <div className="popup flex-col justify-center items-center text-center">
          <h3 className='text-white pt-4 pb-2 text-xl font-semibold'>Fill in the fields</h3>
          <input
            type="text"
            name="googleSheetId"
            placeholder='Google Sheet ID'
            value={inputValues.googleSheetId}
            onChange={handleInputChange}
          />
          <input
            type="text"
            name="title"
            placeholder='Name for the Sheet'
            value={inputValues.title}
            onChange={handleInputChange}
          />
          <div className="mt-4 space-x-4">
            <button className="block w-1/4 bg-cef2 hover:bg-cef1 mt-4 rounded-2xl text-ceftext text-center font-semibold mb-2 cursor-pointer"
                    onClick={handleFormSubmit}>Submit</button>
            <button className="block w-1/4 bg-cef2 hover:bg-cef1 mt-4 rounded-2xl text-ceftext text-center font-semibold mb-2 cursor-pointer"
                    onClick={handleClosePopup}>Cancel</button>
          </div>
        </div>
      )}
</div>
  )
};

export default Import;
