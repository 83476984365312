import React from "react";

const SimpleDropdown = ({ onFormSelect }) => {
  const handleSelect = (event) => {
    onFormSelect(event.target.value);
  };

  return (
    <div className="flex items-center justify-center h-full py-10">
      <div>
        <label htmlFor="form-select" className="block text-sm font-medium text-gray-700">
        </label>
        <select
          id="form-select"
          name="form-select"
          className="mt-1 block w-full py-2 px-3 border border-cefgrey bg-cefblue2 text-ceftext focus:outline-none focus:ring-cefpurple 
            focus:border-cefpurple rounded-md shadow-sm hover:border-white"
          onChange={handleSelect}
        >
          <option value="">--Please choose an Form--</option>
          <option value="form1">5-Day Club</option>
          <option value="form2">GoodNews Camp</option>
          <option value="form3">Teacher Slips</option>
        </select>
      </div>
    </div>
  );
};

export default SimpleDropdown;
