import React, { useState, useEffect } from 'react';
import { getClassTeachers, updateTeacherToClass } from "../../components/services";

export const ListTeachers = ({ id, county }) => {
    const [teacherList, setTeacherList] = useState([]);
    const [selectedTeacher, setSelectedTeacher] = useState(null);
    const [editMode, setEditMode] = useState(false);

    const fetchData = async () => {
        try {
            const teachers = await getClassTeachers(id, county);
            setTeacherList(teachers);
        } catch (error) {
            console.error("Error fetching data:", error);
        }
    };

    useEffect(() => {
   
        fetchData(); 
    }, [id, county]);

    const openTeacherPopup = (teacher) => {
        setSelectedTeacher(teacher);
        setEditMode(false);
    };

    const handleEdit = () => {
        setEditMode(true);
    };

    const handleSave = () => {
        updateTeacherToClass(id, selectedTeacher.id, county, selectedTeacher);
        fetchData();
        setEditMode(false);
    };

    const handleCancel = () => {
        //setSelectedTeacher(teacherList.find(teacher => teacher.id === selectedTeacher.id));
        setEditMode(false);
    };

    return (
        <div className='grid grid-cols-4 gap-4 text-white'>
            <div className=''>
                <div className='grid grid-cols-1 gap-4 max-h-[300px] overflow-y-auto'>
                    {teacherList.map((teacher) => (
                        <div key={teacher.id}>
                            <p className='col-span-3 cursor-pointer hover:text-blue-600'
                                onClick={() => openTeacherPopup(teacher)}>
                                {teacher.firstName} {teacher.lastName}
                            </p>
                        </div>
                    ))}
                </div>
            </div>
            <div className='text-xs'> 
                <h className='font-semibold'>Teacher information:</h>
                <p>Select teacher on the left</p>
                <p> to see additional information</p>
            </div>
            <div className='col-start-3 col-span-2 text-lg'>
                {selectedTeacher && (
                    <div className="popup">
                        <h3 className='font-semibold'>{selectedTeacher.firstName} {selectedTeacher.lastName}</h3>
                        {editMode ? (
                            <div>
                                <div className='text-black'>
                                    <input
                                        type="text"
                                        placeholder="Email"
                                        value={selectedTeacher.email}
                                        onChange={(e) => setSelectedTeacher({ ...selectedTeacher, email: e.target.value })}
                                    />
                                </div>
                                <div className='text-black'>
                                    <input
                                        type="text"
                                        placeholder="Phone"
                                        value={selectedTeacher.phone}
                                        onChange={(e) => setSelectedTeacher({ ...selectedTeacher, phone: e.target.value })}
                                    />
                                </div>
                                <div className='text-black pb-5'>
                                    <input
                                        type="text"
                                        placeholder="Address"
                                        value={selectedTeacher.address}
                                        onChange={(e) => setSelectedTeacher({ ...selectedTeacher, address: e.target.value })}
                                    />
                                </div>
                                {/* Add more input fields as needed */}
                                <div>
                                    <button className='px-5 hover:text-green-400' onClick={handleSave}>Save</button>
                                    <button className='px-5 hover:text-red-700' onClick={handleCancel}>Cancel</button>
                                </div>
                            </div>
                        ) : (
                            <div>
                                <p>Email: {selectedTeacher.email}</p>
                                <p>Phone: {selectedTeacher.phone}</p>
                                <p className='pb-5'>Address: {selectedTeacher.address}</p>
                                {/* Add more information as needed */}
                                <button className='px-4 hover:bg-cef2 hover:py-1 hover:px-5 bg-cef1 rounded-lg' onClick={handleEdit}>Edit</button>
                            </div>
                        )}
                    </div>
                )}
            </div>
        </div>
    );
};
