import React, { useEffect, useState } from "react";
import { BarChart, Bar, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer } from "recharts";
import { getEventByCounty, getAttendanceByCounty } from '../components/services';

const FivedayChart = (prop) => {
  const {county} = prop
  const [chartData, setChartData] = useState([]);
  const [kids, setKids] = useState();
  const [clubs, setClubs] = useState();

  useEffect(() => {
    const getClubCounts = async () => {

      const clubsData = await getEventByCounty('fivedayclubs', county);
      const attendanceData= await getAttendanceByCounty('attendance', county);

      // Filter out records where camp is true
      const filteredAttendanceData = attendanceData.filter((attendance) => attendance.camp !== true || attendance.camp !== true)

      // Calculate attendance counts by month
      const attendanceCounts = [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0];
      for (const attendance of filteredAttendanceData) {
        const attendanceDate = new Date(attendance.date);
        const month = attendanceDate.getMonth();
        attendanceCounts[month]++;
      }
    
      const clubCounts = [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0];
      for (const club of clubsData) {
        const clubDate = new Date(club.date);
        const month = clubDate.getMonth();
        clubCounts[month]++;
      }
        
      // Combine attendance counts and total month counts
      const chartData = attendanceCounts.map((count, index) => ({
        name: new Date(2023, index).toLocaleString("default", { month: "short" }),
        Kids: count,
        Clubs: clubCounts[index]
      }));
    
      // Calculate total attendance counts and total club counts
      const totalAttendance = attendanceCounts.reduce((sum, count) => sum + count, 0);
      const totalClubs = clubCounts.reduce((sum, count) => sum + count, 0);
      setKids(totalAttendance);
      setClubs(totalClubs);

      // Filter chartData to include only data for -1 June to August +1
      const filteredChartData = chartData.slice(4, 9);

      setChartData(filteredChartData);
    };

    getClubCounts();
  }, [county]);

  return (
    <div className="relative bg-cefblue2 shadow-lg z-30 p-1 rounded-lg">
      <h1 className='text-ceftext text-center lg:text-3xl lg:font-bold sm:py-2 sm:text-lg'> 5-Day Clubs </h1>
      <div className=''>
        <ResponsiveContainer width="100%" height={400}>
          <BarChart data={chartData}>
            <CartesianGrid strokeDasharray="3 3" />
            <XAxis dataKey="name" />
            <YAxis />
            <Tooltip />
            <Legend />
            <Bar dataKey="Kids" fill="#ff4500" />
            <Bar dataKey="Clubs" fill="#ffc500" />
            <Legend
              payload={[
                { value: "Kids", type: "rect", color: "#ff4500" },
                { value: "Clubs", type: "rect", color: "#ffc500" },
              ]} />
            <text x="95%" y="10%" textAnchor="end" fontSize="20" fill="#999999">
              5-Day Clubs
            </text>
            <text x="95%" y="15%" textAnchor="end" fontSize="20" fill="#999999">
              Kids: {kids}
            </text>
            <text x="95%" y="20%" textAnchor="end" fontSize="20" fill="#999999">
              Clubs: {clubs}
            </text>
          </BarChart>
        </ResponsiveContainer>
    </div>
  </div>

  );
}

export default FivedayChart;
