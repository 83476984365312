
import React, { useState, useContext } from 'react';
import { UserDataContext } from "../components/UserDataContext";
import { AuthContext } from '../authContext';
import { uploadToPath } from '../components/services';

const FiveDayClub = ({ resetForm }) => {
    const  userData  = useContext(UserDataContext);
    const { currentUser } = useContext(AuthContext);
    const [path] = useState(`/trainingclass/${userData.county}/2023-2024`);
    const [formData, setFormData] = useState({
        month:'',
        day: '',
        time: '',
        offering: '',
        address: '',
        zip:'',
        phone: '',
        hostess: '',
        helper:'',
        county:userData.county,
        teacher:currentUser.uid,
      });
         
      const handleInputChange = (e) => {
        const { name, value } = e.target;
    
        // Capitalize the first letter and keep the rest in lowercase
        const formattedValue = value.charAt(0).toUpperCase() + value.slice(1).toLowerCase();
    
        // Update the state with the formatted value
        setFormData({ ...formData, [name]: formattedValue });
      };

      const handleSubmit = async (e) => {
        e.preventDefault();
        

        uploadToPath(path, formData);
        console.log('Submit button clicked with data:', formData);
        setFormData({
            month:'',
            day: '',
            time: '',
            offering: '',
            address: '',
            zip:'',
            phone: '',
            hostess: '',
            helper:'',
            county:userData.county,
            teacher:currentUser.uid,
        });
      };

    return (
    <div className="flex flex-col py-4 items-center justify-center">
        <div className="w-full h-auto shadow-lg flex justify-start flex-col bg-cefblue rounded-lg">
            <div className="p-2 w-full border-b dark:border-transparent text-center font-bold">
                <span className="text-xl text-ceftext">Teacher’s Monthly Report</span>
            </div>
            <div className="flex flex-col items-center justify-cente border-b border-cefblue">
                <form className="flex place-content-center p-8">
                    <div className="grid w-full gap-6 mb-6 md:grid-cols-3 md:grid-rows-2 sm:grid-cols-1">
                        <div>
                            <label htmlFor="Club" className="block mb-2 text-sm font-semibold text-ceftext">For the month of</label>
                            <input 
                                type="month" 
                                id="month" 
                                name="month" 
                                className="bg-cefblue2 border border-gray-300 text-ceftext text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5" 
                                value = {formData.month} 
                                required
                                placeholder="Month"
                                onChange={handleInputChange}></input>
                        </div>
                        <div>
                            <label htmlFor="Date" className="block mb-2 text-sm font-semibold text-ceftext ">Day of the Week</label>
                            <input 
                                type="text"
                                id="day" 
                                name="day" 
                                className="bg-cefblue2 border border-gray-300 text-ceftext text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5" 
                                placeholder="Day"
                                value={formData.day}
                                required
                                onChange={handleInputChange}></input>
                        </div>
                        <div>
                            <label htmlFor="Time" className="block mb-2 text-sm font-semibold text-ceftext">Time</label>
                            <input 
                                type="time" 
                                id="time" 
                                name="time" 
                                className="bg-cefblue2 border border-gray-300 text-ceftext  text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5" 
                                value={formData.time} 
                                required
                                onChange={handleInputChange}></input>
                        </div>
                        <div>
                            <label htmlFor="Helper" className="block mb-2 text-sm font-semibold text-ceftext">Helper</label>
                            <input 
                                type="text" 
                                id="helper" 
                                name="helper" 
                                className="bg-cefblue2 border border-gray-300 text-ceftext text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5" 
                                value = {formData.helper} 
                                required
                                onChange={handleInputChange}></input>
                        </div>
                        <div>
                            <label htmlFor="Phone" className="block mb-2 text-sm font-semibold text-ceftext ">Phone</label>
                            <input 
                                type="tel" 
                                id="phone"
                                name="phone"  
                                className="bg-cefblue2 border border-gray-300 text-ceftext  text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5" 
                                placeholder="215-324-1112" 
                                pattern="[0-9]{3}-[0-9]{3}-[0-9]{4}" 
                                value={formData.phone}
                                required
                                onChange={handleInputChange}></input>
                        </div>
                        <div className = "col-span-1">
                            <label htmlFor="hostess" className="block mb-2 text-sm font-semibold text-ceftext ">Offering</label>
                            <input 
                                type="number" 
                                id="offering" 
                                name="offering" 
                                className="bg-cefblue2 border border-gray-300 text-ceftext  text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5" 
                                value={formData.offering} 
                                required
                                onChange={handleInputChange}></input>
                        </div>
                        <div className = "col-span-2">
                            <label htmlFor="Address" className="block mb-2 text-sm font-semibold text-ceftext ">Address</label>
                            <input 
                                type="address" 
                                id="address" 
                                name="address" 
                                className="bg-cefblue2 border border-gray-300 text-ceftext  text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5" 
                                value={formData.address} 
                                required
                                onChange={handleInputChange}></input>
                        </div>
                        <div>
                            <label htmlFor="Zip" className="block mb-2 text-sm font-semibold text-ceftext ">Zip</label>
                            <input 
                                type="number" 
                                id="zip" 
                                name="zip" 
                                className="bg-cefblue2 border border-gray-300 text-ceftext  text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5" 
                                value={formData.zip} 
                                required
                                onChange={handleInputChange}></input>
                        </div>
                    </div>
                </form>
            </div>
            <div className="p-2 w-full min-h-full flex items-center justify-center">
            <button className="bg-transparent hover:bg-cefblue2 hover:text-white text-ceftext font-bold py-2 px-4 rounded-l-lg"
                    type="submit"
                    onClick={resetForm}>
                    Back to Forms
                </button>
                <button className="bg-transparent hover:bg-cefblue2 hover:text-white text-ceftext font-bold py-2 px-4 rounded-r-lg"
                    type="submit"
                    onClick={handleSubmit}>
                    Upload Form
                </button>
            </div>
        </div>
    </div>
    );
  };
  
  export default FiveDayClub;
