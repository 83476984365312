import React, { useState } from "react";
import { Icon } from '@iconify/react';
import ShowTeachers from "../helper/loadTeachers";

export default function Teachers () {
    const [isOpen, setIsOpen] = useState(false);

    return(
        <div>
            <div>
                <ShowTeachers />
            </div>
            <div>
            <button className="fixed bottom-5 right-8" onClick={() => setIsOpen(true)}>
                <Icon icon="pepicons-pop:plus-circle" color="white" width="50" height="50" />
            </button>
            </div>
            {isOpen && (
                <div className="fixed top-0 left-0 w-screen h-screen flex justify-center items-center bg-gray-800 bg-opacity-50">
                    <div className="bg-cefblue2 p-8 rounded shadow-lg">
                        {/* Your pop-up content goes here */}
                        <div>
                            This is the content of the pop-up.
                        </div>
                        <button onClick={() => setIsOpen(false)}>
                            Close Pop-up
                        </button>
                    </div>
                </div>
            )}
        </div>
    )
}
