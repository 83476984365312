import React, {useContext} from "react"
import { UserDataContext } from "../components/UserDataContext";
import { Icon } from "@iconify/react";

function Account (){
    const  userData  = useContext(UserDataContext);

    return (
        <div class="grid h-screen grid-cols-1 px-4 pt-6 xl:grid-cols-3 xl:gap-4">
            <div class="col-span-full xl:col-auto">
                <div class="p-4 mb-4 bg-cef2 rounded-xl shadow-sm 2xl:col-span-2">
                    <div class="items-center sm:flex xl:block 2xl:flex sm:space-x-4 xl:space-x-0 2xl:space-x-4">
                        <img class="mb-4 shadow-lg rounded-lg w-28 h-28 sm:mb-0 xl:mb-4 2xl:mb-0" src={userData?.photoUrl} alt="profile" />
                        <div>
                            <h3 class="mb-1 text-xl font-bold text-white">Profile picture</h3>
                            <div class="mb-4 text-sm text-white dark:text-gray-400">
                              JPG, GIF or PNG. Max size of 800K
                            </div>
                            <div class="flex items-center space-x-4">
                                <button type="button" class="py-2 px-3 text-sm font-medium text-gray-900 focus:outline-none bg-cef5 rounded-lg shadow-lg hover:bg-gray-100 hover:text-blue-700 focus:z-10 focus:ring-4 focus:ring-gray-200">
                                    Change Picture
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
           
           
    )

}; export default Account;