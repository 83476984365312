/**
 * Fucntions in 'services.js' fetche particular set of data from Firestore.
 *
 * @example
 * This function retrieves the user data associated with the provided `currentUser`
 * identifier from the Firestore database. It performs an asynchronous operation to
 * retrieve the user document and returns the document snapshot if it exists.
 *
 * @param {string} currentUser - The identifier of the current user.
 * @returns {Promise<DocumentSnapshot|null>} A Promise that resolves to the retrieved
 * user document snapshot if it exists; otherwise, it resolves to `null`.
 *
 * @throws {Error} If there's an error while fetching user data from Firestore.
 *
 *  * const currentUserID = 'user123'; // Replace with actual user identifier
 * const userDataSnapshot = await getUserData(currentUserID);
 * if (userDataSnapshot) {
 *   // User data exists, handle the snapshot
 *   const userData = userDataSnapshot.data();
 *   // ... do something with userData ...
 * } else {
 *   // User data does not exist
 *   console.log('User data not found.');
 * }
 */

import { 
    doc, collection, getDoc, updateDoc, query, 
    orderBy, getDocs, addDoc, where, collectionGroup, writeBatch } 
                     from 'firebase/firestore';
import { ref, getDownloadURL } from "firebase/storage";

import { firestore, storage } from '../utils/firebaseConfig';

/*********************  SIMPLE FUNCTIONS **********************/
export const uploadToPath = async (path, data) => {
  try {
    // Assuming 'path' is the name of your Firestore collection
    const docRef = await addDoc(collection(firestore, path), data);
    console.log(`Data uploaded successfully to ${path} with ID: ${docRef.id}`);
  } catch (error) {
    console.error('Error uploading data to Firestore:', error);
  }
};

export const fetchDataFromPath = async (path) => {
  try {
    const collectionRef = collection(firestore, path);
    const querySnapshot = await getDocs(collectionRef);

    const data = [];

    querySnapshot.forEach((doc) => {
      // Access each document's data here and push it into the array
      const documentData = doc.data();
      data.push({ id: doc.id, ...documentData });
    });

    return data; // This will be an array of JSON objects
  } catch (error) {
    console.error('Error fetching data from Firestore:', error);
    throw error; // Optionally, you can throw the error for handling in the calling code
  }
};

/*********************  TRAINING FUNCTIONS **********************/
export const createTraining = (formData, newCounty) => {
  const countyRef = doc(firestore, 'trainingclass', newCounty)
  const classRef= collection(countyRef, '2023-2024');
  addDoc(classRef, {
    date: formData.date,
    time: formData.time,
    location: formData.location,
    county: newCounty
  })
}

export const getTrainingClasses = async (county) => {
  try {
    // Reference the "2023-2024" collection within the specified county
    const classQuery = query(collection(firestore, 'trainingclass', county, '2023-2024'));

    // Execute the query and obtain a query snapshot
    const querySnapshot = await getDocs(classQuery);

    // Map over the documents in the query snapshot and create an array of objects
    return querySnapshot.docs.map(doc => ({ ...doc.data(), id: doc.id,}));
  } catch (error) {
    console.error('Error fetching Training classes by county and year:', error);
    return null;
  }
};

export const getClassTeachers = async (id,county) => {
  try{
    const classQuery = query(collection(firestore, 'trainingclass', county, '2023-2024', id, 'teachers'));
    const querySnapshot = await getDocs(classQuery);
    return querySnapshot.docs.map(doc => ({ ...doc.data(), id: doc.id }));
  } catch (error) {
    return null;
  }
};

export const getClassTeachersCount = async (id,county) => {
  try{
    const classQuery = query(collection(firestore, 'trainingclass', county, '2023-2024', id, 'teachers'));
    const querySnapshot = await getDocs(classQuery);
    return querySnapshot.size;
  } catch (error) {
    throw null;
  }
};

export const addTeacherToClass = async (id, county, teacherData) => {
  try {
    const classRef = doc(firestore, 'trainingclass', county, '2023-2024', id);
    const teachersCollectionRef = collection(classRef, 'teachers');
    const newTeacherDocRef = await addDoc(teachersCollectionRef, teacherData);   
    return newTeacherDocRef.id; // Return the ID of the newly added teacher document
  } catch (error) {
    console.error("Error adding teacher:", error);
    return null;
  }
};

export const updateTeacherToClass = async (classId, teacherId, county, teacherData) => {
  try {
    // Reference to the specific document within the subcollection
    const teacherDocRef = doc(firestore, 'trainingclass', county, '2023-2024', classId, 'teachers', teacherId);

    // Update the document with the specified data
    await updateDoc(teacherDocRef, teacherData);
  } catch (error) {
    console.error("Error updating teacher:", error);
    return null;
  }
};

/*********************  USER FUNCTIONS **********************/
/**
 * Fetches user data from Firestore based on the provided user identifier.
 *
 * @param {string} currentUser - The user identifier.
 * @returns {Promise<DocumentSnapshot|null>} The user data snapshot if it exists; otherwise, `null`.
 */
export const getUserData = async (currentUser) => {
  if (currentUser) {
    const userDocRef = doc(collection(firestore, 'users'), currentUser);
    try {
      const userDocSnapshot = await getDoc(userDocRef);
      if (userDocSnapshot.exists()) {
        return userDocSnapshot;
      } else {
        console.log('User document does not exist.');
      }
    } catch (error) {
      console.error('Error fetching user data:', error);
    }
  } else {
    console.log('No currentUser provided.');
  }
  return null;
};

/**
 * Get all users from a Firestore collection.
 *
 * @param {string} county - The county of users to be returned
 * @returns {Promise<Array>} Promise resolving to an array of event objects.
 */
export const getAllUsersByCounty = async (county) => { 
    try {
      const usersQuery = query(collection(firestore, 'users'), where('county', '==', county));
      const querySnapshot = await getDocs(usersQuery);  
      return querySnapshot.docs.map(doc => ({ ...doc.data(), id: doc.id}));
    } catch (error) {
      console.error('Error fetching users by county:', error);
      return null;
    }
  };

/**
 * Fetch the image URL for a user based on their document key from Firebase Storage.
 *
 * @param {string} userKey - The document key of the user.
 * @returns {Promise<string|null>} A promise that resolves to the image URL or null if not found.
 */
async function getImageUrlByUserKey(userKey) {
  try {
    const storageRef = ref(storage, `profile-pictures/${userKey}.jpg`); // Replace with your storage reference
    const downloadURL = await getDownloadURL(storageRef);
    return downloadURL;
  } catch (error) {
    // Handle any errors that occurred while fetching the URL
    console.error("Error getting download URL:", error);
    const storageRef = ref(storage, `profile-pictures/generic-profile.jpg`); // Replace with your storage reference
    const downloadURL = await getDownloadURL(storageRef);
    return downloadURL; // Return null or handle the error as needed
  }
}
export default getImageUrlByUserKey;

/**
 * Update user data in Firestore.
 *
 * @param {string} userId - User identifier.
 * @param {Object} updatedData - Updated user data.
 * @returns {Promise<void>} Resolves after data update.
 */
export const updateUserData = async (userId, updatedData) => {
    try {
      const userDocRef = doc(firestore, 'users', userId);
      await updateDoc(userDocRef, updatedData);
      console.log('User data updated successfully!');
    } catch (error) {
      console.error('Error updating user data:', error);
    }
  };



 /*********************  EVENT FUNCTIONS **********************/
 /**
 * Get all events from a Firestore collection.
 *
 * @param {string} collectionName - Name of the Firestore collection.
 * @param {string} sortField - Field to sort by.
 * @param {string} sortOrder - Sorting order ('asc' or 'desc').
 * @returns {Promise<Array>} Promise resolving to an array of event objects.
 */ 
  export const getAllClubs = async (collectionName, sortField, sortOrder) => {
    const myCollection = collection(firestore, collectionName);
    const sortedQuery = query(myCollection, orderBy(sortField, sortOrder));
    const snapshot = await getDocs(sortedQuery);
    return snapshot.docs.map(doc => ({ ...doc.data(), id: doc.id }));
  }

/**
 * Get filtered events from a Firestore collection by county.
 *
 * @param {string} collectionName - Name of the Firestore collection.
 * @param {string} sortField - Field to sort by.
 * @param {string} sortOrder - Sorting order ('asc' or 'desc').
 * @param {string} county - County to filter events by.
 * @returns {Promise<Array>} Promise resolving to an array of event objects.
 */
  export const getEventByCountyFitered = async (collectionName, sortField, sortOrder, county) => {
    const myCollection = collection(firestore, collectionName);
    const sortedQuery = query(myCollection, where('county', '==', county), orderBy(sortField, sortOrder));
    const snapshot = await getDocs(sortedQuery);
  
    if (snapshot.empty) {
      console.log(`${collectionName} is empty.`);
      return [];
    }
  
    console.log(`${collectionName} has ${snapshot.size} document(s).`);
  
    return snapshot.docs.map(doc => ({ ...doc.data(), id: doc.id }));
  };

/**
 * Get events from a Firestore collection by county.
 *
 * @param {string} collectionName - Name of the Firestore collection.
 * @param {string} county - County to filter events by.
 * @returns {Promise<Array>} Promise resolving to an array of event objects.
 */
  export const getEventByCounty = async (collectionName, county) => {
    const myCollection = collection(firestore, collectionName);
    const sortedQuery = query(myCollection, where('county', '==', county));
    const snapshot = await getDocs(sortedQuery);
    
    return snapshot.docs.map(doc => ({ ...doc.data()}));
  };

/**
 * Get the total number of events from a Firestore collection within a county for a specific year.
 *
 * @param {string} collectionName - Name of the Firestore collection.
 * @param {string} county - County to filter events by.
 * @returns {Promise<number>} Promise resolving to the total number of events.
 */
  export const getEventsByCountyByYear = async (collectionName, county) => {
    const myCollection = collection(firestore, collectionName);
    const myQuery = query(myCollection, where('county', '==', county), where("date", ">=", "2023-01-01"), where("date", "<=", "2023-12-31"));
    const snapshot = await getDocs(myQuery);
    const total = snapshot.size;
    return total;
  };


/*********************  ATTENDANCE FUNCTIONS **********************/
/**
 * Get attendance records from a Event within a county and a specific year.
 *
 * @param {string} collectionName - Name of the Event.
 * @param {string} county - County to filter attendance by.
 * @returns {Promise<Array>} Promise resolving to an array of attendance records.
 */
  export const getAttendanceByCounty = async (collectionName, county) => {
    const myCollection = collectionGroup(firestore, collectionName);
    const myQuery = query(myCollection, where('county', '==', county), where("date", ">=", "2023-01-01"), where("date", "<=", "2023-12-31"));
    const snapshot = await getDocs(myQuery);
       
    return snapshot.docs.map(doc => ({ ...doc.data()}));
  };

/**
 * Get attendance records for an Event.
 *
 * @param {string} collectionName - Name of the event.
 * @param {string} id - Identifier of the event.
 * @returns {Promise<Array>} Promise resolving to an array of attendance records for the event.
 */
  export const getAttendanceByEvent = async (collectionName, id) => {
    const eventref= doc(firestore, collectionName, id);
    const attendanceRef = collection(eventref, 'attendance');
    const snapshot = await getDocs(attendanceRef);
      
    return snapshot.docs.map(doc => ({ ...doc.data(), id: doc.id, editable: false }));
  }; 

/**
 * Get the total count of attendance records.
 *
 * @returns {Promise<number>} Promise resolving to the total count of attendance records.
 */
  export const getAttendanceCount = async () => {
    const attendanceCollection = collectionGroup(firestore, "attendance");
    const attendanceSnapshot = await getDocs(attendanceCollection);
    const attendanceCount = attendanceSnapshot.size;
    return attendanceCount;
  }; 

/**
 * Get the total count of attendance records for a specific event.
 *
 * @param {string} id - Identifier of the event.
 * @param {string} collectionName - Name of the event.
 * @returns {Promise<number>} Promise resolving to the total count of attendance records for the event.
 */  
  export const getAttendancePerEvent = async (id, collectionName) => {
    const eventId = id;
    const eventRef = doc(firestore, collectionName, eventId);
    const attendanceCollection = collection(eventRef, "attendance");
    const attendanceSnapshot = await getDocs(attendanceCollection);
    const attendanceCount = attendanceSnapshot.size;
    return attendanceCount;
  };


/*********************  TOTALS FOR EVENTS FUNCTIONS **********************/
/**
 * Get the total count of attendance records for a specific day of the week from a event.
 *
 * @param {string} id - Identifier of the event.
 * @param {string} day - Day of the week to filter attendance records.
 * @param {string} collectionName - Name of the event.
 * @returns {Promise<number>} Promise resolving to the total count of attendance records for the day.
 */
  export const GetTotalDay = async (id, day, collectionName) => {
    const eventId = id;
    const eventRef = doc(firestore, collectionName, eventId);
    const myCollection = collection(eventRef, "attendance");
    const myQuery = query(myCollection, where(day, '==', true));
    const snapshot = await getDocs(myQuery);
    const total = snapshot.size;
    return total;
  };

/**
 * Get the total count of records of childeren that are churched from a event.
 *
 * @param {string} id - Identifier of the event.
 * @param {string} day - Day of the week to filter attendance records.
 * @param {string} collectionName - Name of the event.
 * @returns {Promise<number>} Promise resolving to the total count of records of childeren that are churched from a event.
 */
  export const GetTotaChurched = async (id, collectionName) => {
    const eventId = id;
    const eventRef = doc(firestore, collectionName, eventId);
    const myCollection = collection(eventRef, "attendance");
    const myQuery = query(myCollection, where('church', '==', true));
    const snapshot = await getDocs(myQuery);
    const total = snapshot.size;
    return total;
  };

/**
 * Get the total count of records of childeren that made profession of faith from a event.
 *
 * @param {string} id - Identifier of the event.
 * @param {string} day - Day of the week to filter attendance records.
 * @param {string} collectionName - Name of the event.
 * @returns {Promise<number>} Promise resolving to the total count of records of childeren that made profession of faith from a event.
 */
  export const GetTotalProf = async (id, collectionName) => {
    const eventId = id;
    const eventRef = doc(firestore, collectionName, eventId);
    const myCollection = collection(eventRef, "attendance");
    const myQuery = query(myCollection, where('profession', '==', true));
    const snapshot = await getDocs(myQuery);
    const total = snapshot.size;
    return total;
  };

/**
 * Get the total count of records of childeren that came of assurance from a event.
 *
 * @param {string} id - Identifier of the event.
 * @param {string} day - Day of the week to filter attendance records.
 * @param {string} collectionName - Name of the event.
 * @returns {Promise<number>} Promise resolving to the total count of records of childeren that came of assurance from a event.
 */  
  export const GetTotalAss = async (id, collectionName) => {
    const eventId = id;
    const eventRef = doc(firestore, collectionName, eventId);
    const myCollection = collection(eventRef, "attendance");
    const myQuery = query(myCollection, where('assurance', '==', true));
    const snapshot = await getDocs(myQuery);
    const total = snapshot.size;
    return total;
  };



  /*********************  FIREBASE COLLECTION FUNCTIONS **********************/
/**
 * Update the fields in Firestore collections or collection group.
 * 
 * The function can be altered depending on what is neccesary check, check the code before using it
 *
 * @param {string} 'name of the collection/group' - Identifier of collection/group.
 * @param {string} 'field' - Name of the field in the collection.
 * @param {string} 'change' - New Data.
 * 
 * IMPORTANT: This function is intended for use by authorized database moderators only.
 * It updates the field for records in the specified collection group.
 * Incorrect use can alter submitted data. Exercise caution.
 */
  export const updateCollection = async () => {
    const collection = collectionGroup(firestore, "name of the collection/group");
    const querySnapshot  = await getDocs(collection);

    const batch = writeBatch(firestore);

    // Update each document in the subcollection
    querySnapshot.forEach((doc) => {
      const collectionRef = doc.ref;
      const collectionData = doc.data();

      // Check if the field already exists
      if (!collectionData.hasOwnProperty("field")) {
          batch.update(collectionRef, { field: "change" });
      }
  });

  // Commit the batch
  try {
      await batch.commit();
      console.log("Update completed.");
  } catch (error) {
      console.error("Update failed:", error);
  }
  }; 