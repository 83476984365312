import React, { useState, useContext } from 'react';
import Clubs from './sub-pages/clubs';
import Camp from './sub-pages/camp';
import Training from './sub-pages/training';
import { UserDataContext } from '../components/UserDataContext';

const Insights = () => {
    const userData  = useContext(UserDataContext);
    const [page, setPage] = useState('');
  
      // Define a component based on the current page state
      let content = null;
      if (page === 'fivedayclubs') {
        content = <Clubs county={userData.county} />;
    } else if (page === 'goodnewscamp') {
        content = <Camp county={userData.county} />;
    } else if (page === 'trainingcenters') {
        content = <Training  county={userData.county}/>;
    }
    

  return (
    <>
        <div className="xs:px-6 sm:px-8 md:px-10">
            <h1 className='text-ceftext text-center py-20 md:py-8 text-xl md:text-5xl font-bold'> Insights </h1>
        </div>
        <div className="flex justify-between items-center text-ceftext xs:p-4 sm:p-6 md:p-8 lg:p-10 xl:p-20">
            <button className="hover:text-blue-500 focus:ring-4 focus:outline-none focus:ring-grey-800 font-bold rounded-lg text-sm lg:px-12 lg:py-3 md:px-5 sm:px-3 sm:py-2 xs:px-3 xs:py-2 text-center" 
                onClick={() => setPage('fivedayclubs')}>
                Five Day Clubs
            </button>
            <button className="hover:text-blue-500 focus:ring-4 focus:outline-none focus:ring-grey-800 font-bold rounded-lg text-sm lg:px-12 lg:py-3 md:px-5 sm:px-3 sm:py-2 xs:px-3 xs:py-2 text-center" 
                onClick={() => setPage('goodnewscamp')}>
                Good News Camp
            </button>
            <button className="hover:text-blue-500 focus:ring-4 focus:outline-none focus:ring-grey-800 font-bold rounded-lg text-sm lg:px-12 lg:py-3 md:px-5 sm:px-3 sm:py-2 xs:px-3 xs:py-2 text-center" 
                onClick={() => setPage('trainingcenters')}>
                Training Class
            </button>
        </div>
        {content}
    </>
  );
};

export default Insights;