import React, { useState, useEffect } from "react";
import { Icon } from "@iconify/react";
import { AddTeacher } from "./addTeacher";
import { ListTeachers } from "./listTeachers";
import { createTraining, getTrainingClasses, getClassTeachersCount } from "../../components/services";

export default function Training(props) {
  const { county } = props;
  const [isOpen, setIsOpen] = useState(false);
  const [showAttendance, setShowAttendance] = useState(false);
  const [attendanceId, setAttendanceId] = useState(null);
  const [formData, setFormData] = useState({
    date: "",
    time: "",
    location: "",   
  });

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleSubmit = (e) => {
    createTraining(formData, county);
    e.preventDefault();
    setFormData({
        date: "",
        time: "",
        location: "",
      });
    setIsOpen(false); // Close the popup after form submission
  };

  function CreateClasses({ training }) {
    const [sortField, setSortField] = useState('');
    const [sortOrder, setSortOrder] = useState('asc');
    const [limitValue, setLimitValue] = useState(10);
    const [pageNumber, setPageNumber] = useState(1);
    const [searchValue, setSearchValue] = useState('');
    const [displayedTraining, setDisplayedTraining] = useState([]);

    const openAttendance = (id) => {
      setAttendanceId(id);
      setShowAttendance(true);
    };

    useEffect(() => {

      const sortClubs = (training) => {
          const sortedTraining= [...training];
  
          sortedTraining.sort((a, b) => {
              if (a[sortField] < b[sortField]) {
                return sortOrder === 'asc' ? -1 : 1;
              } else if (a[sortField] > b[sortField]) {
                return sortOrder === 'asc' ? 1 : -1;
              } else {
                  return 0;
              }
          });
  
              return sortedTraining;
          };

      const searchValueLower = searchValue.toLowerCase().trim();
      let filteredTraining = training;

      if (searchValueLower) {
          filteredTraining = training.filter(training =>
          training.date.toLowerCase().includes(searchValueLower) ||
          training.location.toLowerCase().includes(searchValueLower) ||
          training.time.toLowerCase().includes(searchValueLower) 
      );
      }

      const sortedTraining = sortClubs(filteredTraining);

      setDisplayedTraining(sortedTraining.slice((pageNumber - 1) * limitValue, pageNumber * limitValue));
      }, [training, limitValue, pageNumber, searchValue, sortField, sortOrder ]);

     

      const handleSort = (field) => {

          if (sortField === field) {
              setSortOrder(sortOrder === 'asc' ? 'desc' : 'asc');
          } else {
              setSortField(field);
              setSortOrder('asc');
          }
      };

      const handleLimitValue = (event) => {
          setLimitValue(Number(event.target.value));
          setPageNumber(1);
      };

      const handlePrevPage = () => {
          setPageNumber(pageNumber - 1);
      };

      const handleNextPage = () => {
          setPageNumber(pageNumber + 1);
      };

      const handleSearchValue = (event) => {
          setSearchValue(event.target.value);
          setPageNumber(1);
      };

  const start = (pageNumber - 1) * limitValue;
  const end = pageNumber * limitValue;

    return (
      <div className="container mx-auto p-10 rounded-lg xs:p-4 sm:p-6 md:p-6 lg:p-12 xl:p-16 ">
          <form>
            <label htmlFor="search" className="mb-2 text-sm font-medium text-ceftext"></label>
            <div className="relative">
              <div className="absolute inset-y-0 left-0 flex items-center pl-3 pointer-events-none">
                <Icon icon="raphael:search" />
              </div>
              <input
                type="search"
                id="search"
                className="block w-full p-2 pl-10 text-sm text-black border border-gray-300 rounded-lg bg-gray-50 focus:ring-blue-500 focus:border-blue-500"
                placeholder="Search by Hostess, Date, Address or Time"
                value={searchValue}
                onChange={handleSearchValue} />
            </div>
          </form>
          <div className="flex justify-between items-center mb-6 pt-4">
            <h1 className="text-3xl text-ceftext font-semibold">Teacher Training Classes</h1>
            <form onSubmit={event => event.preventDefault()}>
            </form>
          </div>
          <div className="table-container overflow-x-auto">
            <table className="table-auto w-full text-sm md:text-base text-left">
              <thead className="text-sm text-ceftext bg-gray-50">
                <tr className="bg-cefblue2">
                  <th scope="col"
                    className="px-6 py-3 hover:text-blue-500 cursor-pointer border-b"
                    onClick={() => handleSort('date')}
                  >
                    Date
                  </th>
                  <th scope="col"
                    className="px-6 py-3 border-b"
                  >
                    Time
                  </th>
                  <th scope="col"
                    className="px-6 py-3 border-b"
                  >
                    Location
                  </th>
                </tr>
              </thead>
              <tbody className=''>
                {displayedTraining.map((training) => (
                  <tr key={training.id} className="bg-cefblue2 text-ceftext">
                    <td className="px-6 py-4">{training.date}</td>
                    <td className="px-6 py-4">{training.time}</td>
                    <td className="px-6 py-2">{training.location}</td>
                    <td className="bg-cefblue text-center">
                      <button className="text-cefgrey hover:text-red-700" onClick={() => openAttendance(training.id)}>
                        <Icon icon="tabler:eye-edit" width="30" height="30" />
                      </button>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
          <div className="flex justify-between items-center mt-6">
            <div className='text-ceftext'>
              Showing {start + 1} to {Math.min(end, training.length)} of {training.length} Training Class
            </div>
            <div className="flex items-center text-black">
              <button
                className="flex items-center bg-gray-200   rounded-l-md border-r border-gray-100 py-2 hover:bg-red-700 hover:text-ceftext px-3"
                onClick={handlePrevPage}
                disabled={pageNumber === 1}
              >
                <Icon icon="material-symbols:arrow-circle-left" />
                <span className="ml-2 text-sm font-medium">Prev</span>
              </button>
              <button
                className="flex items-center bg-gray-200   rounded-r-md border-r border-gray-100 py-2 hover:bg-red-700 hover:text-ceftext px-3"
                onClick={handleNextPage}
                disabled={end >= training.length}
              >
                <span className="mr-2 text-sm font-medium">Next</span>
                <Icon icon="material-symbols:arrow-circle-right" />
              </button>
              <div className="ml-2">
                {pageNumber}/{Math.ceil(training.length / limitValue)}
              </div>
              <div className="ml-2">
                <select value={limitValue} onChange={handleLimitValue} className="bg-gray-200 rounded text-sm font-medium">
                  <option value={10}>10 per page</option>
                  <option value={15}>15 per page</option>
                  <option value={20}>20 per page</option>
                </select>
              </div>
            </div>
          </div>
        </div>
    );
  }

  function LoadClasses (){
    const [data, setData] = useState([]);
    
    useEffect(() => {
        const fetchData = async () => {
            try {
                const result = await getTrainingClasses(county);
                setData(result);
            } catch (error) {
                console.error("Error fetching data:", error);
            }
        };

        if (county) {
            fetchData();
          }
    }, []);
    return (
        <div className="">
                <CreateClasses training={data}/>
        </div>
        
    );
  }

  function Attendance() {
    const [selectedTab, setSelectedTab] = useState("list");
    return (
      <div className="fixed top-0 left-0 w-screen h-screen flex items-center justify-center bg-black bg-opacity-80">
        <div className="bg-cefblue2 p-10 rounded shadow-lg">
          {/* Tabs */}
          <div className="flex justify-center mb-4 text-ceftext">
            <button
              className={`rounded px-4 py-1 hover:bg-blue-700 hover:text-white hover:px-5 hover:py-2 hover:font-semibold  ${
                selectedTab === "list" ? "bg-cefblue text-ceftext" : ""
              }`}
              onClick={() => setSelectedTab("list")}
            >
              List           
            </button>
            <button
              className={`rounded px-4 py-1 hover:bg-blue-700 hover:text-white hover:px-5 hover:py-2 hover:font-semibold  ${
                selectedTab === "add" ? "bg-cefblue text-ceftext" : ""
              }`}
              onClick={() => setSelectedTab("add")}
            >
              Add
            </button>
          </div>
  
          {/* Content based on selectedTab */}
          {selectedTab === "list" && (
              <ListTeachers id={attendanceId} county={county} />
          )}
  
          {selectedTab === "add" && (
              <AddTeacher id={attendanceId} county={county} />
          )}
  
          {/* Close button */}
          <button
            className="text-ceftext font-semibold mt-4 hover:bg-red-700 hover:p-1 hover:font-bold hover:text-lg p-1 rounded"
            onClick={() => {
              setSelectedTab(null);
              setShowAttendance(false);
            }}
          >
            Close
          </button>
        </div>
      </div>
    );
  }

  return (
    <div className="container my-24 mx-auto md:px-6 text-white">

      <LoadClasses />
      <div>
        <button
          className="fixed bottom-5 right-8"
          onClick={() => setIsOpen(true)}
        >
          <Icon
            icon="pepicons-pop:plus-circle"
            color="999"
            width="50"
            height="50"
          />
        </button>
      </div>
      {isOpen && (
        <div className="fixed top-0 left-0 w-screen h-screen flex justify-center items-center bg-gray-800 bg-opacity-50">
          <div className="bg-cefblue2 p-8 rounded shadow-lg relative">
            <button
              className="absolute top-2 right-2"
              onClick={() => setIsOpen(false)}
            >
              <Icon icon="carbon:close-outline" width="25" height="25" />
            </button>
            <div className="flex md:w-1/2 py-8 items-center bg-cefblue2">
              <form onSubmit={handleSubmit} className="bg-cefblue2">
                <h1 className="text-white font-bold text-xl text-center mb-3">
                  Training Class Details
                </h1>
                <div className="flex items-center border-2 py-2 px-3 rounded-2xl mb-4">
                  <input
                    className="pl-2 outline-none border-none bg-transparent placeholder:text-white"
                    type="date"
                    name="date"
                    id="date"
                    placeholder="Date"
                    value={formData.date}
                    onChange={handleInputChange}
                  />
                </div>
                <div className="flex items-center border-2 py-2 px-3 rounded-2xl mb-4">
                  <input
                    className="pl-2 outline-none border-none bg-transparent"
                    type="time"
                    name="time"
                    id="time"
                    placeholder="Time"
                    value={formData.time}
                    onChange={handleInputChange}
                  />
                </div>
                <div className="flex items-center border-2 py-2 px-3 rounded-2xl mb-4">
                  <input
                    className="pl-2 outline-none border-none bg-transparent placeholder:text-white"
                    type="text"
                    name="location"
                    id="location"
                    placeholder="Location"
                    value={formData.location}
                    onChange={handleInputChange}
                  />
                </div>
                <button
                  type="submit"
                  className="block w-full bg-cefblue mt-4 py-2 rounded-2xl text-ceftext font-semibold mb-2"
                >
                  Submit
                </button>
              </form>
            </div>
          </div>
        </div>
      )}
      {showAttendance && (
        <Attendance/>
      )}
    </div>
  );
}
