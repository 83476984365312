import React from "react";
import ForgetPassword from "../pages/forgetPassword";

function PasswordResetModal({ onClose }) {
  return (
    <div className="fixed inset-0 flex items-center justify-center z-50">
      <div className="absolute inset-0 bg-black opacity-50"></div>
      <div className="z-10 bg-cefblue2 p-4 rounded shadow-md">
        <h2 className="text-xl font-semibold mb-4">Reset Password</h2>
        <ForgetPassword onClose={onClose} />
      </div>
    </div>
  );
}

export default PasswordResetModal;
