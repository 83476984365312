import React, { useState, useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import { AuthContext } from '../authContext';
import { 
  signInWithEmailAndPassword, 
  setPersistence, 
  browserSessionPersistence,
} from 'firebase/auth';
import { auth } from '../utils/firebaseConfig';
import { Icon } from '@iconify/react';
import PasswordResetModal from '../components/passwordResetModal';

const Login = () => {
  const navigate = useNavigate();
  const { setUser } = useContext(AuthContext);

  const [emailAddress, setEmailAddress] = useState('');
  const [password, setPassword] = useState('');
  const [error, setError] = useState('');
  const [isModalOpen, setIsModalOpen] = useState(false);

  const openModal = () => {
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    setPersistence(auth, browserSessionPersistence)
      .then(() => {
        try {
          signInWithEmailAndPassword(auth, emailAddress, password)
            .then((userCredential) => {
              setUser(userCredential.user);
              navigate('/dashboard');
            })
            .catch((error) => {
              if (error.code === 'auth/wrong-password') {
                setError('Wrong password. Please try again.');
              } else if (error.code === 'auth/user-not-found') {
                setError('User not found. Please check your email address.');
              } else {
                setError(error.message);
              }
              setEmailAddress('');
              setPassword('');
            });
        } catch (error) {
          setEmailAddress('');
          setPassword('');
          setError(error.message);
        }
      });
  };

  return (
    <div className="flex h-screen items-center justify-center bg-cefblue py-12 px-4 sm:px-6 lg:px-8">
      <div className="w-full max-w-md space-y-8">
        <div>
          <img
            className="mx-auto h-40 w-auto"
            src="./images/logo.png"
            alt="CEF"
          />
          <h2 className="mt-6 text-center text-2xl font-bold tracking-tight text-ceftext">
            Sign in to your CEF business account
          </h2>
          {error && (
            <div className="flex item-center justify-center pt-2 text-red-600 text-center">{error}</div>
          )}
        </div>
        <form className="mt-8 space-y-6" onSubmit={handleSubmit}>
          <input type="hidden" name="remember" defaultValue="true" />
          <div className="-space-y-px rounded-md shadow-sm">
            <div>
              <label htmlFor="email-address" className="sr-only">
                Email address
              </label>
              <input
                id="email-address"
                name="email"
                type="email"
                autoComplete="email"
                required
                className="relative block w-full rounded-t-md border-0 py-1.5 text-gray-900 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:z-10 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                placeholder="   Email address"
                value={emailAddress}
                onChange={(e) => setEmailAddress(e.target.value)}
              />
            </div>
            <div>
              <label htmlFor="password" className="sr-only">
                Password
              </label>
              <input
                id="password"
                name="password"
                type="password"
                autoComplete="current-password"
                required
                className="relative block w-full rounded-b-md border-0 py-1.5 text-gray-900 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:z-10 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                placeholder="   Password"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
              />
            </div>
          </div>
          <div>
            <button
              type="submit"
              className="group relative flex w-full justify-center rounded-md bg-indigo-600 py-2 px-3 text-sm font-semibold text-ceftext hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
            >
              <span className="absolute inset-y-0 left-0 flex items-center pl-3">
                <Icon icon="ion:lock-closed-outline" />
              </span>
              Sign in
            </button>
          </div>
        </form>
        <div className="flex items-center justify-between">
          <div>
            <button className='text-sm text-center text-ceftext' onClick={openModal}>Forgot your password?</button>
            {isModalOpen && <PasswordResetModal onClose={closeModal} />}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Login;
