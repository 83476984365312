import React, {useContext, useEffect, useState} from "react";
import { UserDataContext } from "../components/UserDataContext";
import { getAttendanceByCounty } from "../components/services";

export const Header = () => {
    const userData  = useContext(UserDataContext);
    const [kids, setKids] = useState();

    useEffect(() => {
        const fetchData = async () => {
            try {
                const data = await getAttendanceByCounty('attendance', userData.county);
                setKids(data.length);   
            }catch (error) {
                console.error(error);
            }
        }

        fetchData();
    });


    return(
        <div className='hidden md:grid grid-cols-6 gap-4'>
            <div className='text-ceftext bg-cef1 shadow-lg rounded-md text-left p-4 my-2 text-xl md:text-xl font-bold'>Total Kids: {kids}</div>
            <div className='text-ceftext bg-cef2 shadow-lg rounded-md text-left p-4 my-2 text-xl md:text-xl font-bold'>5-Day Clubs:</div>
            <div className='text-ceftext bg-cef3 shadow-lg rounded-md text-left p-4 my-2 text-xl md:text-xl font-bold'>Goodnews Clubs:</div>
            <div className='text-ceftext bg-cef4 shadow-lg rounded-md text-left p-4 my-2 text-xl md:text-xl font-bold'>Camps:</div>
            <div className='flex col-start-6 items-center bg-cef5 shadow-lg rounded-md text-center text-white p-4 m-2 text-xl md:text-xl font-bold capitalize'>
                {userData.county}
            </div>

        </div>
    )
};