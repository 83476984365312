import React, { useContext } from 'react';
import { UserDataContext } from '../components/UserDataContext';
import FivedayChart from '../charts/fivedayChart';
import GoodnewsChart from '../charts/goodnewschart';
import AgeChart from '../charts/ageChart';
import { Header } from '../helper/headerDash';

/**
 * Displays the user dashboard with various charts and data.
 *
 * @returns {JSX.Element} - React component displaying the user dashboard.
 */
function Dashboard() {
    const userData = useContext(UserDataContext);

    // Return loading or error message if userData is still null
    if (userData === null) {
        return <p>Loading...</p>;
    }

    return (
        <div className="xs:px-6 sm:px-14 mt-20 mb-10 md:px-8 md:mt-4 lg:px-4 md:h-screen">
            {/* Dashboard title */}
            <Header/>
            <div className='md:h-screen md:w-full'>
                <div className="grid md:grid-row-1 lg:grid-cols-3 md:gap-4 sm:grid-cols-1 gap-4">
                    <div>

                    </div>
                    <div className='relative z-20 lg:row-start-2 lg:col-span-2'>    
                        <FivedayChart county={userData.county} />
                    </div>
                    <div className='lg:row-start-2 sm:col-span-1'> 
                        <AgeChart county={userData.county} />
                    </div>
                    <div className='lg:row-start-3'>
                        <GoodnewsChart county={userData.county} />
                    </div>
                </div>
            </div>

        </div>
    );
}
  
export default Dashboard;
  