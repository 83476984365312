import React, { useState } from 'react';
import { addTeacherToClass } from "../../components/services";
import Papa from 'papaparse';
import { importDataToFirestore } from '../../components/cloudeServices';

export const AddTeacher = ({ id, county }) => {
  const collectionName = `/trainingclass/${county}/2023-2024/${id}/teachers`;
  const [formData, setFormData] = useState({
    firstName: '',
    lastName: '',
    phone: '',
    email: '',
    address: '',
  });

  const handleInputChange = (e) => {
    const { name, value } = e.target;

    // Check if it's a "firstName" or "lastName" field
    if (name === "firstName" || name === "lastName") {
      // Capitalize the first letter and keep the rest in lowercase
      const formattedValue =
        value.charAt(0).toUpperCase() + value.slice(1).toLowerCase();

      // Update the state with the formatted value
      setFormData({ ...formData, [name]: formattedValue });
    } else {
      // For other fields, simply update the state
      setFormData({ ...formData, [name]: value });
    }
  };

  const handleImportClick = async (e) => {
    const file = e.target.files[0];
  
    if (!file) {
      console.error('Please select a CSV file.');
      return;
    }
  
    // Check the file type based on its extension
    const fileType = file.name.split('.').pop().toLowerCase();
  
    if (fileType !== 'csv') {
      console.error('Unsupported file type. Please select a CSV file.');
      return;
    }
  
    // Parse the CSV file into JSON format on the client-side
    try {
      const jsonData = await parseCSVFile(file);
      console.log('Parsed JSON data:', jsonData);
  
      // Send the JSON data to your Cloud Function for Firestore import
      await importDataToFirestore(collectionName, jsonData);
    } catch (error) {
      console.error('Error parsing CSV file:', error);
    }
  };
  
  const parseCSVFile = (file) => {
    return new Promise((resolve, reject) => {
      Papa.parse(file, {
        header: true,
        dynamicTyping: true,
        skipEmptyLines: 'greedy', // Skip rows with missing values
        transformHeader: (header) => header.trim(), // Trim whitespace from headers
        transform: (value) => (value === null || value === undefined ? "" : value.toString().trim()), // Set empty values to ""
        complete: (result) => {
          if (result.errors.length > 0) {
            reject(result.errors[0]);
          } else {
            resolve(result.data);
          }
        },
      });
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    // Handle the form submission action
    addTeacherToClass(id, county, formData);
    setFormData({
      firstName: '',
      lastName: '',
      phone: '',
      email: '',
      address: '',
    });

    console.log('Submit button clicked with data:', formData);
  };

    return (
        <><div className="flex md:w-1/2 pt-8 items-center bg-cefblue2">
            <form onSubmit={handleSubmit} className="bg-cefblue2">
                <h1 className="text-ceftext font-bold text-xl text-center mb-3">
                    Teacher Info
                </h1>
                <div className="flex items-center hover:border-2 border-ceftext py-2 px-3 rounded-2xl mb-4">
                    <input
                        className="pl-2 outline-none border-none bg-cefblue2"
                        type="text"
                        name="firstName"
                        id="firstName"
                        placeholder="First Name"
                        value={formData.firstName}
                        onChange={handleInputChange}
                        required
                        aria-describedby="name-error" />
                </div>
                <div className="flex items-center hover:border-2 border-ceftext py-2 px-3 rounded-2xl mb-4">
                    <input
                        className="pl-2 outline-none border-none bg-cefblue2"
                        type="text"
                        name="lastName"
                        id="lastName"
                        placeholder="Last Name"
                        value={formData.lastName}
                        onChange={handleInputChange}
                        required
                        aria-describedby="name-error" />
                </div>
                <div className="flex items-center hover:border-2 border-ceftext py-2 px-3 rounded-2xl mb-4">
                    <input
                        className="pl-2 outline-none border-none bg-cefblue2"
                        type="text"
                        name="phone"
                        id="phone"
                        placeholder="Phone"
                        value={formData.phone}
                        onChange={handleInputChange} />
                </div>
                <div className="flex items-center hover:border-2 border-ceftext py-2 px-3 rounded-2xl mb-4">
                    <input
                        className="pl-2 outline-none border-none bg-cefblue2"
                        type="text"
                        name="email "
                        id="email "
                        placeholder="Email "
                        value={formData.Email}
                        onChange={handleInputChange} />
                </div>
                <div className="flex items-center hover:border-2 border-ceftext py-2 px-3 rounded-2xl mb-4">
                    <input
                        className="pl-2 outline-none border-none bg-cefblue2"
                        type="text"
                        name="address"
                        id="address"
                        placeholder="Address"
                        value={formData.address}
                        onChange={handleInputChange} />
                </div>
                <button
                    type="submit"
                    className="block w-full bg-cefblue hover:bg-red-800 mt-4 py-2 rounded-2xl text-ceftext font-semibold mb-2">
                    Submit
                </button>
            </form>
        </div>
        <input
            type="file"
            accept=".csv"
            name="csvFile"
            id="csvFile"
            onChange={handleImportClick}
            className="hidden"
        />
        <label
            htmlFor="csvFile"
            className="block w-full bg-green-800 hover:bg-red-800 mt-4 py-2 rounded-2xl text-ceftext text-center font-semibold mb-2 cursor-pointer"
        >
            Import CSV File
        </label>
    </>
    );
};