// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import { getStorage } from "firebase/storage";
import { getAuth } from "firebase/auth";
import { getFirestore } from "firebase/firestore";

const firebaseConfig = {
  apiKey: "AIzaSyB0-uePYvmFXk2KJjttpSJ9f5FdmwoSDIU",
  authDomain: "employee-portal-8f758.firebaseapp.com",
  databaseURL: "https://employee-portal-8f758-default-rtdb.firebaseio.com",
  projectId: "employee-portal-8f758",
  storageBucket: "employee-portal-8f758.appspot.com",
  messagingSenderId: "19111211270",
  appId: "1:19111211270:web:ab7c98cf055f28892eae87",
  measurementId: "G-ESZF1YL6T3"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const auth = getAuth (app);
const storage = getStorage(app);
const analytics = getAnalytics(app);
const firestore = getFirestore(app);

export { app, auth, storage, analytics, firestore};
