import React, { useState } from "react";
import { Icon } from "@iconify/react";
import { auth } from "../utils/firebaseConfig";
import { sendPasswordResetEmail } from "firebase/auth";

const ForgetPassword = ({ onClose }) => {
  const [emailAddress, setEmailAddress] = useState("");
  const [error, setError] = useState("");
  const [successMessage, setSuccessMessage] = useState("");

  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      await sendPasswordResetEmail(auth, emailAddress);
      setSuccessMessage(
        "A password reset email has been sent to your email address. Please check your inbox."
      );
      setError(""); // Clear any previous errors
    } catch (err) {
      if (err.code === "auth/user-not-found") {
        setError("The provided email does not exist.");
      } else {
        setError("Failed to send reset email. Please check your email address.");
      }
      setSuccessMessage(""); // Clear any previous success message
    }
  };

  return (
    <div className="fixed inset-0 flex items-center justify-center bg-opacity-50 bg-black">
      <div className="w-full max-w-md bg-cefblue2 rounded-lg p-6">
        <div>
          <h2 className="mt-2 text-2xl text-center font-bold text-gray-900">
            Send Email To Reset Password
          </h2>
          {error && (
            <div className="mt-2 text-red-600 text-center">{error}</div>
          )}
          {successMessage && (
            <div className="mt-2 text-green-600 text-center">{successMessage}</div>
          )}
        </div>
        <form className="mt-4" onSubmit={handleSubmit}>
          <div>
            <label htmlFor="email-address" className="block text-sm font-medium text-gray-700">
              Email address
            </label>
            <input
              id="email-address"
              name="email"
              type="email"
              autoComplete="email"
              required
              className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
              placeholder="Email address"
              value={emailAddress}
              onChange={(e) => setEmailAddress(e.target.value)}
            />
          </div>
          <div className="mt-4">
            <button
              type="submit"
              className="w-full flex justify-center py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-ceftext bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
            >
              <span className="absolute left-0 inset-y-0 flex items-center pl-3">
                <Icon icon="ion:lock-closed-outline" className="h-5 w-5 text-indigo-500" />
              </span>
              Reset Password
            </button>
          </div>
          <div className="mt-2">
            <button
              type="button"
              onClick={onClose}
              className="w-full flex justify-center py-2 px-4 border border-gray-300 rounded-md text-sm font-medium text-gray-700 bg-cefblue2 hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
            >
              Cancel
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default ForgetPassword;
